import React from 'react';
import EmptyListMessage from '../../_shared/EmptyListMessage';

interface EmptyWantListProps {
    isOwnProfile: boolean;
    user: string | undefined;
    isAuthenticated: boolean;
}

const EmptyWantList: React.FC<EmptyWantListProps> = (props) => {
    const messages = {
        title: "want list",
        description: "Start building your want list by adding cards you're looking for. This helps others know what you're interested in trading or acquiring!"
    };

    return (
        <EmptyListMessage
            {...props}
            editPath="/collections/my/want/edit"
            messages={messages}
        />
    );
};

export default EmptyWantList;
