import React from 'react';
import { CardVariantTypeEnum } from '../../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import { CardData } from '../../../../types/wantListTypes';
import { useAspects } from '../../../../hooks/useAspects';
import { getFormattedImageUrl } from '../../../../utils/imageUtils';

interface WantCardProps {
    wantedCard: CardData;
    isOwnWantList: boolean;
    variantType?: CardVariantTypeEnum;
}

const WantCard: React.FC<WantCardProps> = ({ wantedCard, variantType }) => {
    const { aspects } = useAspects();
    const getAspectImageUrl = (aspectType: string) => {
        const aspect = aspects.find(a => a.value === aspectType);
        return aspect ? getFormattedImageUrl(aspect.image, 20) : '';
    };

    const renderQuantityBadge = () => {
        if (!variantType) return null;

        const count = wantedCard.variants[variantType]?.wantCount;
        if (!count || count <= 1) return null;

        return (
            <div className="want-count-badge">
                ×{count}
            </div>
        );
    };

    const renderVariants = () => {
        if (variantType) {
            const count = wantedCard.variants[variantType]?.wantCount;
            if (!count) return null;

            return count > 1 ? (
                <div className="want-count want-count--grouped">
                    <span className="want-count__value">×{count}</span>
                </div>
            ) : null;
        }

        return (
            <div className="want-card__variants">
                {Object.entries(wantedCard.variants).map(([variantType, variantData]) => (
                    <span
                        key={variantData.id}
                        className="variant-pill"
                    >
                        {getCardVariantTypeDisplay(variantType as CardVariantTypeEnum)}
                        {variantData.wantCount > 1 ? ` (${variantData.wantCount}x)` : ''}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div className={`want-card want-card--grouped`}>
            <header className="want-card__header">
                <span className="want-card__number" aria-label="Card Number">
                    {wantedCard.standardFullCardNumber}
                </span>
                <h3 className="want-card__name">{wantedCard.name}</h3>
                <div className="want-card__aspects">
                    {wantedCard.aspects?.map((aspect, index) => (
                        <img
                            key={index}
                            src={getAspectImageUrl(aspect)}
                            alt={aspect}
                            title={aspect}
                            className="aspect-icon"
                        />
                    ))}
                </div>

                {renderQuantityBadge()}
            </header>
            {renderVariants()}
        </div>
    );
};

export default WantCard;
