import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CardSetCodeEnum, useGetUserSaleListsForEditQuery } from '../../../../graphql/generated/graphql';
import CardPicker from './card_picker/CardPicker';
import EditableSaleListItemsTable from './EditableSaleListItemsTable';
import { SaleListProvider, VariantState } from './SaleListContext';

interface SaleListEditorProps {
    effectiveHandle: string | undefined;
    onItemsUpdated?: () => void;
}

const SaleListEditor: React.FC<SaleListEditorProps> = ({
    effectiveHandle,
    onItemsUpdated
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedSet, setSelectedSet] = useState<CardSetCodeEnum | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showExtrasOnly, setShowExtrasOnly] = useState(false);

    const { data, loading, refetch } = useGetUserSaleListsForEditQuery({
        variables: { userHandle: effectiveHandle || '' },
        skip: !effectiveHandle
    });

    const handleItemsUpdated = () => {
        refetch();
        onItemsUpdated?.();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSet(null);
        setSearchTerm('');
        setShowExtrasOnly(false);
    };

    if (loading || !data?.userSaleLists?.[0]) return null;

    const saleList = data.userSaleLists[0];

    const initialVariants = saleList.items.reduce((acc, item) => {
        if (item.cardVariant?.id) {
            acc[item.cardVariant.id] = {
                saleCount: item.quantity,
                priceInCents: item.priceInCents,
                condition: item.condition
            };
        }
        return acc;
    }, {} as Record<string, VariantState>);

    return (
        <SaleListProvider initialVariants={initialVariants}>
            <div>
                <div className="d-flex justify-content-end mb-3">
                    <Button
                        onClick={() => setShowModal(true)}
                        variant="add"
                    >
                        <FontAwesomeIcon icon={faPlus} className="me-2" />
                        Add Cards
                    </Button>
                </div>

                <EditableSaleListItemsTable
                    items={(saleList.items?.filter((item): item is NonNullable<typeof item> => item !== null) ?? [])
                        .map(item => ({
                            id: item.id,
                            saleListId: saleList.id,
                            quantity: item.quantity,
                            priceInCents: item.priceInCents,
                            condition: item.condition,
                            extraCount: 0,
                            cardVariant: {
                                id: item.cardVariant.id,
                                cardNumber: item.cardVariant.cardNumber,
                                variantType: item.cardVariant.variantType,
                                card: {
                                    id: item.cardVariant.card.id,
                                    name: item.cardVariant.card.name,
                                    standardFullCardNumber: item.cardVariant.card.standardFullCardNumber,
                                    aspects: item.cardVariant.card.aspects || []
                                }
                            }
                        }))}
                    saleListId={saleList.id}
                    onItemUpdated={handleItemsUpdated}
                />

                <CardPicker
                    show={showModal}
                    onHide={handleCloseModal}
                    selectedSet={selectedSet}
                    onSelectSet={setSelectedSet}
                    searchTerm={searchTerm}
                    onSearchChange={setSearchTerm}
                    showExtrasOnly={showExtrasOnly}
                    onShowExtrasOnlyChange={setShowExtrasOnly}
                    effectiveHandle={effectiveHandle}
                    onItemUpdated={handleItemsUpdated}
                    existingSaleList={saleList}
                />
            </div>
        </SaleListProvider>
    );
};

export default SaleListEditor;
