import React, { createContext, useContext, useState } from 'react';
import { SaleListItemConditionEnum } from '../../../../graphql/generated/graphql';

export interface VariantState {
    saleCount: number;
    priceInCents: number;
    condition: SaleListItemConditionEnum;
}

interface SaleListContextType {
    variants: Record<string, VariantState>;
    updateVariant: (variantId: string, data: VariantState) => void;
    removeVariant: (variantId: string) => void;
}

const SaleListContext = createContext<SaleListContextType | undefined>(undefined);

export const SaleListProvider: React.FC<{ children: React.ReactNode; initialVariants?: Record<string, VariantState> }> = ({ children, initialVariants = {} }) => {
    const [variants, setVariants] = useState<Record<string, VariantState>>(initialVariants);

    const updateVariant = (variantId: string, data: VariantState) => {
        setVariants(prev => ({
            ...prev,
            [variantId]: data
        }));
    };

    const removeVariant = (variantId: string) => {
        setVariants(prev => {
            const next = { ...prev };
            delete next[variantId];
            return next;
        });
    };

    return (
        <SaleListContext.Provider value={{ variants, updateVariant, removeVariant }}>
            {children}
        </SaleListContext.Provider>
    );
};

export const useSaleList = () => {
    const context = useContext(SaleListContext);
    if (!context) throw new Error('useSaleList must be used within SaleListProvider');
    return context;
};
