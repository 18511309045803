import React, { useState } from 'react';
import InventoryField from './input_fields/InventoryField';
import MissingField from './input_fields/MissingField';
import { UpdateCollectionVariantInventoryMutation } from '../../../../graphql/generated/graphql';


interface InventoryEditProps {
    variantId: number;
    initialInventory: number;
    initialMissing: { count: number | null; isDefault: boolean };
    onInventoryUpdate: (variantId: number, field: 'inventory' | 'missing', newValue: number | null, oldValue: number | null) => Promise<UpdateCollectionVariantInventoryMutation>;
}

const InventoryEdit: React.FC<InventoryEditProps> = ({
    variantId,
    initialInventory,
    initialMissing,
    onInventoryUpdate
}) => {
    const [inventory, setInventory] = useState(initialInventory);
    const [missing, setMissing] = useState(initialMissing);

    const handleUpdate = async (field: 'inventory' | 'missing', newValue: number | null, oldValue: number | null) => {
        try {
            const result = await onInventoryUpdate(variantId, field, newValue, oldValue);
            if (result.updateCollectionVariantInventory?.collectionItemInventory) {
                const updatedInventory = result.updateCollectionVariantInventory.collectionItemInventory;
                setInventory(updatedInventory.inventory);
                setMissing(updatedInventory.missing);
            }
        } catch (error) {
            console.error('Error updating inventory:', error);
            // Revert to previous values on error
            setInventory(inventory);
            setMissing(missing);
        }
    };

    return (
        <div className="inventory-edit-container">
            <div className="inventory-item column inventory">
                <label>
                    Have
                </label>
                <InventoryField
                    initialValue={inventory}
                    onUpdate={(newValue) => handleUpdate('inventory', newValue, inventory)}
                />
            </div>
            <div className="inventory-item column missing">
                <label>
                    Need
                </label>
                <MissingField
                    initialValue={missing}
                    onUpdate={(newValue) => handleUpdate('missing', newValue, missing?.count)}
                />
            </div>
        </div>
    );

};

export default InventoryEdit;
