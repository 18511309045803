import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { getFormattedImageUrl } from '../../utils/imageUtils';
import Skeleton from '../layout/Skeleton';

interface CardSetCardProps {
    id: string;
    code: string;
    name: string;
    description: string | null;
    imageUrl: string | null;
}

export const CardSetSkeleton: React.FC = () => (
    <Card className="h-100 shadow-sm hover-card gradient-card">
        <Skeleton height="200px" className="card-img-top" />
        <Card.Body className="card-content">
            <Skeleton height="24px" width="70%" className="mb-2" />
            <Skeleton height="60px" className="mb-3" />
            <Skeleton height="38px" width="50%" />
        </Card.Body>
    </Card>
);

const CardSetCard: React.FC<CardSetCardProps> = ({ id, code, name, description, imageUrl }) => {
    return (
        <Card className="h-100 hover-card gradient-card">
            <Card.Img
                variant="top"
                src={getFormattedImageUrl(imageUrl, 300)}
                alt={name}
            />
            <Card.Body className="d-flex flex-column">
                <Card.Title>{name}</Card.Title>
                <Card.Text className="flex-grow-1">
                    {description}
                </Card.Text>
                <Link
                    to={`/card-sets/${code || id}`}
                    className="btn btn-outline-primary mt-auto"
                >
                    <FontAwesomeIcon icon={faEye} className="me-2" /> View Set
                </Link>
            </Card.Body>
        </Card>
    );
};

export default CardSetCard;
