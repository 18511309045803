import React from 'react';
import { Form } from 'react-bootstrap';
import { WantListGroupBy } from '../../../../types/wantListTypes';

interface WantListGroupControlProps {
    groupBy: WantListGroupBy;
    onChange: (groupBy: WantListGroupBy) => void;
}

const WantListGroupControl: React.FC<WantListGroupControlProps> = ({ groupBy, onChange }) => {
    return (
        <Form.Group className="d-flex align-items-center gap-2">
            <Form.Label className="mb-0">Group by:</Form.Label>
            <Form.Select
                size="sm"
                value={groupBy}
                onChange={(e) => onChange(e.target.value as WantListGroupBy)}
                style={{ width: 'auto' }}
            >
                <option value={WantListGroupBy.CardNumber}>Card Number</option>
                <option value={WantListGroupBy.VariantType}>Variant Type</option>
            </Form.Select>
        </Form.Group>
    );
};

export default WantListGroupControl;
