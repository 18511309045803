import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface EmptyListMessageProps {
    isOwnProfile: boolean;
    user: string | undefined;
    isAuthenticated: boolean;
    editPath: string;
    messages: {
        title: string;
        description: string;
    };
    showCollectionButton?: boolean;
}

const EmptyListMessage: React.FC<EmptyListMessageProps> = ({
    isOwnProfile,
    user,
    isAuthenticated,
    editPath,
    messages
}) => {
    return (
        <Container className="mt-4">
            <div className="text-center py-5">
                {isOwnProfile ? (
                    <>
                        <h2 className="mb-4">You don&apos;t have a {messages.title} yet</h2>
                        <p className="mb-5">{messages.description}</p>
                        {isAuthenticated && (
                            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">
                                <Link to={editPath} className="text-decoration-none">
                                    <Button variant="outline-primary" className="btn-icon-text">
                                        Start a new list
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <h2 className="mb-3">{user} doesn&apos;t have a {messages.title} yet</h2>
                        <p>Check back later to see what cards they&apos;re looking for!</p>
                    </>
                )}
            </div>
        </Container>
    );
};

export default EmptyListMessage;
