import React from 'react';
import { CardVariantTypeEnum } from '../../../../graphql/generated/graphql';
import { orderedVariantTypes, getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import WantCard from './WantCard';
import { CardData } from '../../../../types/wantListTypes';

interface GroupedWantCardsProps {
    cards: CardData[];
    isOwnWantList: boolean;
}

const GroupedWantCards: React.FC<GroupedWantCardsProps> = ({ cards, isOwnWantList }) => {
    const groupedCards = orderedVariantTypes.reduce((acc, variantType) => {
        const cardsWithVariant = cards.filter(card =>
            card.variants[variantType]?.wantCount > 0
        );

        if (cardsWithVariant.length > 0) {
            acc[variantType] = cardsWithVariant;
        }
        return acc;
    }, {} as Record<CardVariantTypeEnum, CardData[]>);

    return (
        <div>
            {orderedVariantTypes.map(variantType => {
                const variantCards = groupedCards[variantType];
                if (!variantCards?.length) return null;

                return (
                    <section key={variantType} className="section__subsection">
                        <h3 className="section__subsection-title">
                            {getCardVariantTypeDisplay(variantType)}
                        </h3>
                        <div className="list-grid">
                            {variantCards.map(card => (
                                <WantCard
                                    key={`${card.id}-${variantType}`}
                                    wantedCard={card}
                                    isOwnWantList={isOwnWantList}
                                    variantType={variantType}
                                />
                            ))}
                        </div>
                    </section>
                );
            })}
        </div>
    );
};

export default GroupedWantCards;
