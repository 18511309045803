import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface TraitFilterProps {
    traits: string[];
    selected: string[];
    onChange: (selected: string[]) => void;
    disabled?: boolean;
}

const TraitFilter: React.FC<TraitFilterProps> = ({ traits, selected, onChange, disabled }) => {
    const options = traits.map(trait => ({ value: trait, label: trait }));
    const value = selected.map(trait => ({ value: trait, label: trait }));

    return (
        <Form.Group className="mb-3">
            <Form.Label>Traits</Form.Label>
            <Select
                isMulti
                options={options}
                value={value}
                onChange={(selected) => onChange(selected ? selected.map(option => option.value) : [])}
                placeholder="Filter by traits..."
                className="form-control-select"
                classNamePrefix="select"
                isDisabled={disabled}
            />
        </Form.Group>
    );
};

export default TraitFilter;
