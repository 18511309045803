import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOfferDetailsQuery, useAddOfferNoteMutation } from '../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../utils/cardUtils';
import { formatDateTime } from "../../utils/dateUtils";
import { useAuth } from '../../contexts/AuthContext';
import { Button, Form } from 'react-bootstrap';

const OfferDetails: React.FC = () => {
    const { offerId } = useParams();
    const { user } = useAuth();
    const [newNote, setNewNote] = useState('');
    const { data, loading, error } = useGetOfferDetailsQuery({
        variables: { offerId: offerId! },
    });
    const [addNote] = useAddOfferNoteMutation();

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!data?.offer) return <p>Offer not found</p>;

    const { offer } = data;

    const handleAddNote = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!newNote.trim()) return;

        try {
            await addNote({
                variables: {
                    offerId: offerId!,
                    content: newNote.trim()
                },
                refetchQueries: ['GetOfferDetails']
            });
            setNewNote('');
        } catch (error) {
            console.error('Error adding note:', error);
        }
    };

    const renderMessages = () => {
        let currentAuthor: string | null = null;

        return [...offer.notes]
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
            .map((note, index, array) => {
                const isOwn = note.user.id === user?.id;
                const isNewAuthor = currentAuthor !== note.user.handle;
                const timeDiff = index > 0 ?
                    new Date(note.createdAt).getTime() - new Date(array[index - 1].createdAt).getTime()
                    : 0;
                const showAuthor = !isOwn && (isNewAuthor || timeDiff > 5 * 60 * 1000); // Only show other users' handles

                currentAuthor = note.user.handle;

                return (
                    <div key={note.id} className={`message-group ${isOwn ? 'message-group--own' : ''}`}>
                        {showAuthor && (
                            <div className="message-author">
                                {note.user.handle}
                            </div>
                        )}
                        <div className="message">
                            <div className="message__bubble">
                                <div className="message__content">{note.content}</div>
                                <div className="message__meta">{formatDateTime(note.createdAt)}</div>
                            </div>
                        </div>
                    </div>
                );
            });
    };

    return (
        <div className="container mx-auto p-4">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                {/* Left Column - Offer Details */}
                <div className="lg:col-span-2">
                    <div className="offer-details">
                        <div className="header-section mb-6">
                            <h2 className="text-xl font-bold">Offer Details</h2>
                        </div>

                        <div className="info-grid">
                            <div className="info-item">
                                <div className="label">From</div>
                                <div className="value">{offer.initiator.handle}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">To</div>
                                <div className="value">{offer.recipient.handle}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">Type</div>
                                <div className="value">{offer.offerType}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">Status</div>
                                <div className="value">{offer.status}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">Created</div>
                                <div className="value">{formatDateTime(offer.createdAt)}</div>
                            </div>
                            <div className="info-item">
                                <div className="label">Last Updated</div>
                                <div className="value">{formatDateTime(offer.updatedAt)}</div>
                            </div>
                        </div>

                        <div className="offered-cards">
                            <h3>Offered Cards</h3>
                            <div className="space-y-3">
                                {offer.offerItems.map((item) => (
                                    <div key={item.id} className="offer-item">
                                        <div className="card-info">
                                            <span className="card-number">
                                                #{item.cardVariant.card.standardCardNumber}
                                            </span>
                                            <span className="card-name">
                                                {item.cardVariant.card.name}
                                            </span>
                                            <span className="variant-type">
                                                {getCardVariantTypeDisplay(item.cardVariant.variantType)}
                                            </span>
                                        </div>
                                        <div className="quantity-price-info">
                                            {item.quantity} × ${(item.priceInCents / 100).toFixed(2)}
                                            <div className="total">
                                                Total: ${((item.quantity * item.priceInCents) / 100).toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="total-section">
                            <div className="total-value">
                                Total Value: ${(offer.totalPriceInCents / 100).toFixed(2)}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Right Column - Discussion */}
                <div className="lg:col-span-1">
                    <div className="offer-details-discussion">
                        <div className="discussion-header">
                            <h3>Offer Discussion</h3>
                        </div>

                        <div className="discussion-messages">
                            {renderMessages()}
                        </div>

                        <div className="discussion-input">
                            <Form onSubmit={handleAddNote}>
                                <div className="input-group">
                                    <Form.Control
                                        type="text"
                                        value={newNote}
                                        onChange={(e) => setNewNote(e.target.value)}
                                        placeholder="Type a message..."
                                    />
                                    <Button
                                        type="submit"
                                        disabled={!newNote.trim()}
                                        variant="primary"
                                    >
                                        Send
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OfferDetails;
