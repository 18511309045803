import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext';
import AppRoutes from '../routes';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import Layout from './layout/Layout';
import { ThemeProvider } from '../contexts/ThemeContext';

const link = createHttpLink({
    uri: process.env.NODE_ENV === 'production'
        ? 'https://www.swu-dash.com/graphql'
        : 'http://127.0.0.1:3000/graphql',
    credentials: 'same-origin'
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
});

const App: React.FC = () => {
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <ThemeProvider>
                    <Router>
                        <Layout>
                            <AppRoutes />
                        </Layout>
                    </Router>
                </ThemeProvider>
            </AuthProvider>
        </ApolloProvider>
    );
};

export default App;
