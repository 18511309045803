import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icons } from '../../constants/icons';
import { useAuth } from '../../contexts/AuthContext';
import NavLoginButton from '../auth/NavLoginButton';

const Header: React.FC = () => {
    const { isAuthenticated, logout, user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = async () => {
        await logout();
        navigate('/', { replace: true });
    };

    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar bg="dark" variant="dark" expand="lg" expanded={expanded} onToggle={setExpanded}>
            <Container>
                <Navbar.Brand as={Link} to="/">SWU Dashboard</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto" onClick={() => setExpanded(false)}>
                        <Nav.Link as={Link} to="/card-sets">
                            <FontAwesomeIcon icon={Icons.CardSets} className="me-1" /> Card Sets
                        </Nav.Link>
                        {isAuthenticated ? (
                            <>
                                <Nav.Link as={Link} to="/collections/my">
                                    <FontAwesomeIcon icon={Icons.Collection} className="me-1" /> My Collection
                                </Nav.Link>
                                <Nav.Link as={Link} to="/collections/my/want">
                                    <FontAwesomeIcon icon={Icons.Collection} className="me-1" /> My Want List
                                </Nav.Link>
                                <Nav.Link as={Link} to="/collections/my/sell">
                                    <FontAwesomeIcon icon={Icons.Collection} className="me-1" /> My Sale List
                                </Nav.Link>
                                <Nav.Link as={Link} to="/collections">
                                    <FontAwesomeIcon icon={Icons.Collection} className="me-1" /> Shared Collections
                                </Nav.Link>
                                <Nav.Link as={Link} to="/trading">
                                    <FontAwesomeIcon icon={Icons.Trading} className="me-1" /> Trading
                                </Nav.Link>
                            </>
                        ) : (
                            <>
                                <Nav.Link as={Link} to="/collections">
                                    <FontAwesomeIcon icon={Icons.Collection} className="me-1" /> Shared Collections
                                </Nav.Link>
                                <Nav.Link as={Link} to="/trading">
                                    <FontAwesomeIcon icon={Icons.Trading} className="me-1" /> Trading
                                </Nav.Link>
                            </>
                        )}
                    </Nav>
                    <Nav onClick={() => setExpanded(false)}>
                        {isAuthenticated ? (
                            <>
                                {/* Desktop view */}
                                <div className="d-none d-lg-block">
                                    <Dropdown align="end">
                                        <Dropdown.Toggle
                                            variant="link"
                                            className="nav-link p-0 d-flex align-items-center gap-2"
                                            id="profile-dropdown"
                                        >
                                            <div className="rounded-circle bg-secondary d-flex align-items-center justify-content-center"
                                                style={{ width: '2.5rem', height: '2.5rem' }}>
                                                <span className="text-white">{user?.name?.[0]?.toUpperCase()}</span>
                                            </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="dropdown-menu-dark p-2">
                                            <div className="px-3 py-2 mb-2 border-bottom border-secondary">
                                                <div className="fw-medium">{user?.name}</div>
                                                <div className="text-muted small">{user?.email}</div>
                                            </div>
                                            <Dropdown.Item as={Link} to="/profile">
                                                <FontAwesomeIcon icon={Icons.Profile} className="me-2" />
                                                Profile
                                            </Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={handleLogout} className="text-danger">
                                                <FontAwesomeIcon icon={Icons.SignOut} className="me-2" />
                                                Logout
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                {/* Mobile view */}
                                <div className="d-lg-none border-top border-secondary mt-2 pt-2">
                                    <div className="px-3 py-2 d-flex align-items-center gap-2">
                                        <div>
                                            <div className="fw-medium">{user?.name}</div>
                                            <div className="text-muted small">{user?.email}</div>
                                        </div>
                                    </div>
                                    <Nav.Link as={Link} to="/profile" className="mt-2">
                                        <FontAwesomeIcon icon={Icons.Profile} className="me-2" />
                                        Profile
                                    </Nav.Link>
                                    <Nav.Link onClick={handleLogout} className="text-danger">
                                        <FontAwesomeIcon icon={Icons.SignOut} className="me-2" />
                                        Logout
                                    </Nav.Link>
                                </div>
                            </>
                        ) : (
                            <NavLoginButton from={location.pathname} />
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
