import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface CostFilterProps {
    costs: number[];
    selectedCosts: number[];
    onChange: (selectedCosts: number[]) => void;
    disabled?: boolean;
}

const CostFilter: React.FC<CostFilterProps> = ({ costs, selectedCosts, onChange, disabled }) => {
    const costOptions = costs.map(cost => ({ value: cost, label: cost.toString() }));
    const value = selectedCosts.map(cost => ({ value: cost, label: cost.toString() }));

    return (
        <Form.Group className="mb-3">
            <Form.Label>Cost</Form.Label>
            <Select
                isMulti
                options={costOptions}
                value={value}
                onChange={(selected) => onChange(selected ? selected.map(option => option.value) : [])}
                placeholder="Filter by cost..."
                className="form-control-select"
                classNamePrefix="select"
                isDisabled={disabled}
            />
        </Form.Group>
    );
};

export default CostFilter;
