import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../layout/LoadingSpinner';
import ErrorDisplay from '../layout/ErrorDisplay';
import SharedListCard from './SharedListCard';

interface SharedListData {
    id: string;
    user: {
        handle: string;
    };
    updatedAt: string;
    publiclyShared: boolean;
}

interface SharedListGridProps<T extends SharedListData> {
    useQueryHook: () => {
        data?: { [key: string]: T[] };
        loading: boolean;
        error?: Error;
    };
    dataKey: string;
    EmptyState: React.FC;
    getNavigationPath: (handle: string) => string;
}

const SharedListGrid = <T extends SharedListData>({
    useQueryHook,
    dataKey,
    EmptyState,
    getNavigationPath,
}: SharedListGridProps<T>) => {
    const { data, loading, error } = useQueryHook();
    const navigate = useNavigate();

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error.message} />;

    const items = data?.[dataKey] ?? [];

    if (items.length === 0) {
        return (
            <Container className="mt-4">
                <EmptyState />
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            <div className="shared-list__grid">
                {items.map((item) => (
                    <SharedListCard
                        key={item.id}
                        handle={item.user.handle}
                        updatedAt={item.updatedAt}
                        publiclyShared={item.publiclyShared}
                        onClick={() => navigate(getNavigationPath(item.user.handle))}
                    />
                ))}
            </div>
        </Container>
    );
};

export default SharedListGrid;
