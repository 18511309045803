import { CardVariantTypeEnum, AspectTypeEnum } from '../graphql/generated/graphql';

export enum WantListGroupBy {
    CardNumber = 'CARD_NUMBER',
    VariantType = 'VARIANT_TYPE'
}

export interface CardVariantData {
    id: string;
    wantCount: number;
    fullCardNumber: string;
}

export interface CardData {
    id: string;
    name: string;
    standardFullCardNumber: string;
    aspects: AspectTypeEnum[];
    cardSetId: string;
    variants: Record<CardVariantTypeEnum, CardVariantData>;
}
