import React from 'react';
import { Card } from 'react-bootstrap';
import { useGetSharedSaleListsQuery } from '../../graphql/generated/graphql';
import SharedListGrid from './SharedListGrid';

const EmptyState = () => (
    <Card className="banner">
        <h2>No Shared Sale Lists</h2>
        <p>No one has shared their sale lists yet.</p>
    </Card>
);

const SharedSaleLists: React.FC = () => (
    <SharedListGrid
        useQueryHook={useGetSharedSaleListsQuery}
        dataKey="sharedSaleLists"
        EmptyState={EmptyState}
        getNavigationPath={(handle) => `/collections/${handle}/sell`}
    />
);

export default SharedSaleLists;
