import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner, Nav } from 'react-bootstrap';
import { CardVariantTypeEnum, OrientationTypeEnum } from '../../graphql/generated/graphql';
import CardImage from './CardImage';
import { uniqBy, capitalize } from 'lodash';

interface CardImage {
    id: string;
    url?: string | null;
    orientation: OrientationTypeEnum;
}

interface CardVariant {
    id: string;
    variantType?: CardVariantTypeEnum | null;
    images: {
        front: CardImage;
        back?: CardImage | null;
    };
}

interface CardModalData {
    id: string;
    name: string;
    images: {
        front: CardImage;
        back?: CardImage | null;
    };
    cardVariants?: CardVariant[];
}

interface CardModalProps {
    card: CardModalData | null | undefined;
    loading: boolean;
    onClose: () => void;
}

const filterVariants = (variants: CardVariant[]): CardVariant[] => {
    const uniqueVariants = uniqBy(variants, 'images.front.url');
    return uniqueVariants.map(variant => {
        const allVariantsForImage = variants.filter(v => v.images.front.url === variant.images.front.url);
        const nonFoilVariant = allVariantsForImage.find(v =>
            v.variantType === CardVariantTypeEnum.Standard ||
            v.variantType === CardVariantTypeEnum.Hyperspace
        );
        return nonFoilVariant || variant;
    });
};

const CardModal: React.FC<CardModalProps> = ({ card, loading, onClose }) => {
    const [activeVariant, setActiveVariant] = useState(0);
    const [filteredVariants, setFilteredVariants] = useState<CardVariant[]>([]);
    const [showBackImage, setShowBackImage] = useState(false);

    useEffect(() => {
        if (card?.cardVariants) {
            setFilteredVariants(filterVariants(card.cardVariants));
        }
    }, [card]);

    const getImageOrientation = (variant: CardVariant): string => {
        const frontIsLandscape = variant.images.front.orientation === OrientationTypeEnum.Landscape;
        const backIsLandscape = variant.images.back?.orientation === OrientationTypeEnum.Landscape;
        return showBackImage && variant.images.back
            ? (backIsLandscape ? 'landscape' : 'portrait')
            : (frontIsLandscape ? 'landscape' : 'portrait');
    };

    const renderCardImage = (variant: CardVariant) => {
        const frontIsLandscape = variant.images.front.orientation === OrientationTypeEnum.Landscape;
        const backIsLandscape = variant.images.back?.orientation === OrientationTypeEnum.Landscape;
        const sameOrientation = frontIsLandscape === backIsLandscape;

        const orientation = getImageOrientation(variant);
        const flipperClass = `card-flipper ${showBackImage ? 'flipped' : ''} ${sameOrientation ? 'same-orientation' : 'different-orientation'}`;

        return (
            <div className={`game-card-modal__image ${orientation}`}>
                <div className={flipperClass}>
                    <div className="card-face front">
                        <CardImage
                            image={variant.images.front}
                            name={card?.name || ''}
                        />
                    </div>
                    {variant.images.back && (
                        <div className="card-face back">
                            <CardImage
                                image={variant.images.back}
                                name={`${card?.name} (Back)`}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <Modal show centered onHide={onClose} dialogClassName="game-card-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Loading...</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Spinner animation="border" variant="primary" />
                </Modal.Body>
            </Modal>
        );
    }

    if (!card) {
        return (
            <Modal show centered onHide={onClose} dialogClassName="game-card-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>No card data available</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal show centered onHide={onClose} dialogClassName="game-card-modal">
            <Modal.Header closeButton>
                <Modal.Title>{card.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="game-card-modal__content">
                    {filteredVariants.length > 1 && (
                        <Nav className="custom-tabs">
                            {filteredVariants.map((variant, index) => (
                                <Nav.Item key={index}>
                                    <Nav.Link
                                        className={`custom-tab ${activeVariant === index ? 'active' : ''}`}
                                        onClick={() => setActiveVariant(index)}
                                    >
                                        {capitalize(variant.variantType || '')}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    )}
                    {renderCardImage(filteredVariants[activeVariant] || card.cardVariants?.[0])}
                    {card.images.back && (
                        <Button
                            size="sm"
                            onClick={() => setShowBackImage(!showBackImage)}
                            className="mt-3"
                        >
                            {showBackImage ? "Show Front" : "Show Back"}
                        </Button>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CardModal;
