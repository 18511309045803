import React, { useEffect, useState } from 'react';
import { Container, Nav, Button, Card } from 'react-bootstrap';
import OfferList from './trade/OfferList';
import SharedWantLists from './trade/SharedWantLists';
import SharedSaleLists from './trade/SharedSaleLists';
import { useUser } from '../hooks/useUser';
import { OfferTypeEnum, OfferRoleEnum } from '../graphql/generated/graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useSearchParams } from 'react-router-dom';

enum TradingHubTab {
    Offers = 'Offers',
    SharedWantLists = 'SharedWantLists',
    SharedSaleLists = 'SharedSaleLists',
}

const TradingHub: React.FC = () => {
    const { isAuthenticated, hasHandle } = useUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultTab = searchParams.get('tab') as TradingHubTab || TradingHubTab.SharedWantLists;

    const [activeTab, setActiveTab] = useState<TradingHubTab>(defaultTab);
    const [activeRole, setActiveRole] = useState<OfferRoleEnum>(OfferRoleEnum.Recipient);
    const [typeFilter, setTypeFilter] = useState<OfferTypeEnum | undefined>();

    const canTrade = isAuthenticated && hasHandle;

    useEffect(() => {
        if (!searchParams.get('tab')) {
            setSearchParams({ tab: TradingHubTab.SharedWantLists });
        }
    }, [searchParams, setSearchParams]);

    const handleTabChange = (tab: TradingHubTab) => {
        setActiveTab(tab);
        setSearchParams({ tab });
    };

    const renderTab = (tab: TradingHubTab, label: string) => {
        const isActive = activeTab === tab;
        const isDisabled = tab !== TradingHubTab.SharedSaleLists &&
            tab !== TradingHubTab.SharedWantLists &&
            !canTrade;

        return (
            <Nav.Item>
                <Nav.Link
                    className={`custom-tab ${isActive ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
                    onClick={() => !isDisabled && handleTabChange(tab)}
                    disabled={isDisabled}
                >
                    {label}
                </Nav.Link>
            </Nav.Item>
        );
    };

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-4" style={{ color: 'var(--offer-details-title-color)' }}>
                Trading Hub
            </h1>

            {!canTrade && (
                <Card className="banner mb-4">
                    {!isAuthenticated ? (
                        <p className="mb-0">
                            <FontAwesomeIcon icon={faSignInAlt} className="me-2 text-glow--gold" />
                            <Link to="/login">Log in</Link> to send or receive offers.
                        </p>
                    ) : !hasHandle && (
                        <p className="mb-0">
                            <FontAwesomeIcon icon={faUserPlus} className="me-2 text-glow--gold" />
                            <Link to="/profile">Set up your profile</Link> to start trading.
                        </p>
                    )}
                </Card>
            )}

            <Nav variant="tabs" className="custom-tabs mb-4">
                {renderTab(TradingHubTab.SharedWantLists, 'Shared Want Lists')}
                {renderTab(TradingHubTab.SharedSaleLists, 'Shared Sale Lists')}
                {renderTab(TradingHubTab.Offers, 'Offers')}
            </Nav>

            {activeTab === TradingHubTab.Offers && canTrade && (
                <>
                    <div className="d-flex gap-3 align-items-center mb-4">
                        <div className="btn-group">
                            <Button
                                variant={activeRole === OfferRoleEnum.Recipient ? 'primary' : 'outline-primary'}
                                onClick={() => setActiveRole(OfferRoleEnum.Recipient)}
                            >
                                Received
                            </Button>
                            <Button
                                variant={activeRole === OfferRoleEnum.Initiator ? 'primary' : 'outline-primary'}
                                onClick={() => setActiveRole(OfferRoleEnum.Initiator)}
                            >
                                Sent
                            </Button>
                        </div>

                        <select
                            className="form-control form-control--alt w-auto"
                            value={typeFilter || ''}
                            onChange={(e) => setTypeFilter(e.target.value ? e.target.value as OfferTypeEnum : undefined)}
                        >
                            <option value="">All Offers</option>
                            <option value={OfferTypeEnum.Buy}>Buy Offers</option>
                            <option value={OfferTypeEnum.Sell}>Sell Offers</option>
                        </select>
                    </div>
                    <OfferList role={activeRole} filterByType={typeFilter} />
                </>
            )}
            {activeTab === TradingHubTab.SharedWantLists && <SharedWantLists />}
            {activeTab === TradingHubTab.SharedSaleLists && <SharedSaleLists />}
        </Container>
    );
};

export default TradingHub;
