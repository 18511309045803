import React from 'react';
import ErrorDisplay from '../../../layout/ErrorDisplay';
import LoadingSkeleton from '../../../layout/Skeleton';
import SaleCard from './SaleCard';
import EmptySaleList from './EmptySaleList';
import { useAuth } from '../../../../contexts/AuthContext';
import { CardData } from '../../../../types/saleListTypes';
import { ApolloError } from '@apollo/client';
import { compareCardIds } from '../../../../utils/cardUtils';

interface SaleListDisplayProps {
    effectiveHandle: string | undefined;
    isOwnSaleList: boolean;
    cardsData: CardData[];
    loading: boolean;
    error: ApolloError | undefined;
}

const SaleListDisplay: React.FC<SaleListDisplayProps> = ({ effectiveHandle, isOwnSaleList, cardsData, loading, error }) => {
    const { isAuthenticated } = useAuth();

    if (loading) return <LoadingSkeleton />;
    if (error) return <ErrorDisplay message={error.message} />;

    if (cardsData.length === 0) {
        return (
            <EmptySaleList
                isOwnProfile={isOwnSaleList}
                user={effectiveHandle}
                isAuthenticated={isAuthenticated}
            />
        );
    }

    return (
        <div className="list-container">
            <div className="list-grid">
                {cardsData
                    .sort((a, b) => compareCardIds(a.id, b.id))
                    .map((card) => (
                        <SaleCard
                            key={card.id}
                            saleCard={card}
                            isOwnSaleList={isOwnSaleList}
                        />
                    ))}
            </div>
        </div>
    );
};

export default SaleListDisplay;
