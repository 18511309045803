import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faEye, faShare } from '@fortawesome/free-solid-svg-icons';
import WantListDisplay from './display/WantListDisplay';
import WantListEditor from './edit/WantListEditor';
import { useAuth } from '../../../contexts/AuthContext';
import {
    AspectTypeEnum,
    CardVariantTypeEnum,
    useGetUserWantListsForDisplayQuery,
    useGetWantListShareSettingsLazyQuery,
    useUpdateWantListSharingMutation
} from '../../../graphql/generated/graphql';
import ShareCollectionModal from '../_shared/ShareListModal';
import { CardData, CardVariantData } from '../../../types/wantListTypes';
import { useShareSettings } from '../../../hooks/useShareSettings';
import CreateSellOfferModal from '../../trade/CreateSellOfferModal';
import OfferBanner from '../_shared/OfferBanner';
import HandleRequiredBanner from '../_shared/HandleRequiredBanner';

const WantListPage: React.FC = () => {
    // Hooks and state
    const { handle } = useParams<{ handle: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, user } = useAuth();
    const [isEditMode, setIsEditMode] = useState(location.pathname.endsWith('/edit'));
    const [showOfferModal, setShowOfferModal] = useState(false);

    // Path and authorization checks
    const isMyWantList = /^\/collections\/my\/want(\/edit)?$/.test(location.pathname);
    const isOwnWantList = isMyWantList || (isAuthenticated && user?.handle === handle);
    const effectiveHandle = isMyWantList ? user?.handle : handle;
    const displayHandle = isOwnWantList ? 'My' : `${effectiveHandle}'s`;
    const pageTitle = isEditMode ? 'Edit Want List' : `${displayHandle} Want List`;

    // GraphQL queries and mutations
    const { data, loading, error, refetch } = useGetUserWantListsForDisplayQuery({
        variables: { userHandle: effectiveHandle },
        skip: !effectiveHandle,
    });

    const wantListId = data?.userWantLists?.[0]?.id;
    const [getWantListShareSettings] = useGetWantListShareSettingsLazyQuery();
    const [updateWantListSharing] = useUpdateWantListSharingMutation();
    const {
        showShareModal,
        setShowShareModal,
        shareError,
        isSharingLoading,
        shareSettings,
        handleShowShareModal,
        handleSaveSharing
    } = useShareSettings({
        getShareSettings: async (id?: string) => {
            const result = await getWantListShareSettings({
                variables: { wantListId: id || '' },
                fetchPolicy: 'network-only'
            });
            return {
                data: {
                    sharedWithAll: result.data?.wantList?.shareSettings?.sharedWithAll || false,
                    sharedWithUsers: (result.data?.wantList?.shareSettings?.sharedWithUsers || []).map(user => ({
                        id: String(user.id),
                        handle: user.handle || '',
                        email: user.email
                    }))
                }
            };
        },
        updateSharing: async (variables) => {
            const result = await updateWantListSharing({
                variables: {
                    input: {
                        wantListId: wantListId!,
                        ...variables.input
                    }
                }
            });
            return { data: result.data?.updateWantListSharing || { success: false } };
        },
        id: wantListId,
        idFieldName: 'wantListId'
    });

    // Authentication effect
    useEffect(() => {
        if (isMyWantList && !isAuthenticated) {
            navigate('/login', { state: { from: location.pathname } });
        }
        setIsEditMode(location.pathname.endsWith('/edit'));
    }, [isMyWantList, isAuthenticated, navigate, location.pathname]);

    // Event listener effect
    useEffect(() => {
        const handleWantListUpdated = () => refetch();
        window.addEventListener('want-list-updated', handleWantListUpdated);
        return () => window.removeEventListener('want-list-updated', handleWantListUpdated);
    }, [refetch]);

    // Event handlers
    const toggleEditMode = () => {
        const newMode = !isEditMode;
        setIsEditMode(newMode);
        navigate(newMode ? `/collections/my/want/edit` : `/collections/my/want`);
    };

    const handleItemsUpdated = () => {
        window.dispatchEvent(new Event('want-list-updated'));
    };

    const handleCreateOffer = () => {
        setShowOfferModal(true);
    };

    // Data transformation
    const cardsData: CardData[] = useMemo(() => {
        if (!data?.userWantLists?.[0]?.items?.nodes) return [];

        const cardMap = new Map<string, CardData>();

        data.userWantLists[0].items.nodes.forEach((item) => {
            if (!item) return;

            const { card, variantType } = item.cardVariant;
            const cardId = card.id;

            if (!cardMap.has(cardId)) {
                cardMap.set(cardId, {
                    id: cardId,
                    name: card.name,
                    standardFullCardNumber: card.standardFullCardNumber || '',
                    variants: {} as Record<CardVariantTypeEnum, CardVariantData>,
                    aspects: card.aspects as AspectTypeEnum[],
                    cardSetId: card.cardSet.id
                });
            }

            const cardData = cardMap.get(cardId)!;
            if (Object.values(CardVariantTypeEnum).includes(variantType)) {
                cardData.variants[variantType] = {
                    id: item.cardVariant.id,
                    wantCount: item.quantity,
                    fullCardNumber: item.cardVariant.fullCardNumber,
                };
            }
        });

        return Array.from(cardMap.values());
    }, [data]);

    // Handle required message
    if (isMyWantList && !user?.handle) {
        return (
            <Container className="mt-4">
                <HandleRequiredBanner listType="want" />
            </Container>
        );
    }

    return (
        <Container className="mt-4">
            {loading ? (
                <div className="d-flex justify-content-center my-5">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    {!isOwnWantList && data?.userWantLists?.[0]?.items?.nodes?.length > 0 && (
                        <OfferBanner onCreateOffer={handleCreateOffer} listType="want" />
                    )}
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>{pageTitle}</h2>
                        <div className="d-flex align-items-center gap-2">
                            {isOwnWantList && (
                                <Button
                                    onClick={toggleEditMode}
                                    variant="outline-primary"
                                    className="btn-icon"
                                    title={isEditMode ? "View Mode" : "Edit Mode"}
                                >
                                    <FontAwesomeIcon icon={isEditMode ? faEye : faPencilAlt} />
                                </Button>
                            )}
                            {isOwnWantList && !isEditMode && (
                                <Button
                                    onClick={handleShowShareModal}
                                    disabled={loading}
                                    variant="outline-primary"
                                    className="btn-icon"
                                    title="Share List"
                                >
                                    <FontAwesomeIcon icon={faShare} />
                                </Button>
                            )}
                        </div>
                    </div>

                    {isEditMode ? (
                        <WantListEditor
                            effectiveHandle={effectiveHandle}
                            onItemsUpdated={handleItemsUpdated}
                        />
                    ) : (
                        <WantListDisplay
                            effectiveHandle={effectiveHandle}
                            isOwnWantList={isOwnWantList}
                            cardsData={cardsData}
                            loading={loading}
                            error={error}
                        />
                    )}
                </>
            )}
            <ShareCollectionModal
                show={showShareModal}
                onHide={() => setShowShareModal(false)}
                initialSharedWithAll={shareSettings.sharedWithAll}
                onSave={handleSaveSharing}
                isSaving={isSharingLoading}
                error={shareError}
                sharedWithUsers={shareSettings.sharedWithUsers}
                shareableLink={`${window.location.origin}/collections/${effectiveHandle}/want`}
            />
            <CreateSellOfferModal
                show={showOfferModal}
                onHide={() => setShowOfferModal(false)}
                recipientHandle={effectiveHandle!}
                wantListItems={cardsData.map(card => ({
                    id: card.id,
                    cardDetails: {
                        name: card.name,
                        standardFullCardNumber: card.standardFullCardNumber
                    },
                    variantCounts: Object.entries(card.variants).map(([variantType, variant]) => ({
                        variantId: variant.id,
                        variantType,
                        count: variant.wantCount
                    }))
                }))}
            />
        </Container>
    );
};

export default WantListPage;
