import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';


const HomeBannerCard: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Row className="justify-content-center my-5">
        <Col lg={10}>
            <Card className="banner">
                <Card.Body className="py-4">
                    {children}
                </Card.Body>
            </Card>
        </Col>
    </Row>
);

export default HomeBannerCard;
