import React, { useMemo } from 'react';
import ErrorDisplay from '../../../layout/ErrorDisplay';
import LoadingSkeleton from '../../../layout/Skeleton';
import WantCard from './WantCard';
import EmptyWantList from './EmptyWantList';
import { useAuth } from '../../../../contexts/AuthContext';
import { CardData, WantListGroupBy } from '../../../../types/wantListTypes';
import { ApolloError } from '@apollo/client';
import { compareCardIds } from '../../../../utils/cardUtils';
import { useSearchParams } from 'react-router-dom';
import WantListGroupControl from './WantListGroupControl';
import GroupedWantCards from './GroupedWantCards';
import { useGetCardSetsQuery } from '../../../../graphql/generated/graphql';

interface WantListDisplayProps {
    effectiveHandle: string | undefined;
    isOwnWantList: boolean;
    cardsData: CardData[];
    loading: boolean;
    error: ApolloError | undefined;
}

const WantListDisplay: React.FC<WantListDisplayProps> = ({ effectiveHandle, isOwnWantList, cardsData, loading, error }) => {
    const { data: setsData } = useGetCardSetsQuery();
    const { isAuthenticated } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const groupBy = (searchParams.get('groupBy') as WantListGroupBy) || WantListGroupBy.CardNumber;

    const setNames = useMemo(() => {
        return setsData?.cardSets?.nodes?.reduce((acc, set) => {
            if (set?.id) acc[set.id] = set.name;
            return acc;
        }, {} as Record<string, string>) ?? {};
    }, [setsData]);

    if (loading) return <LoadingSkeleton />;
    if (error) return <ErrorDisplay message={error.message} />;
    if (cardsData.length === 0) {
        return <EmptyWantList isOwnProfile={isOwnWantList} user={effectiveHandle} isAuthenticated={isAuthenticated} />;
    }

    const cardsBySet = cardsData.reduce((acc, card) => {
        const setId = card.cardSetId;
        if (!acc[setId]) acc[setId] = [];
        acc[setId].push(card);
        return acc;
    }, {} as Record<string, CardData[]>);

    return (
        <div className="list-container">
            <div className="d-flex justify-content-end mb-3">
                <WantListGroupControl
                    groupBy={groupBy}
                    onChange={(newGroupBy) => {
                        setSearchParams(prev => {
                            const params = new URLSearchParams(prev);
                            params.set('groupBy', newGroupBy);
                            return params;
                        }, { replace: true });
                    }}
                />
            </div>

            {Object.entries(cardsBySet).map(([setId, cards]) => (
                <section key={setId}>
                    <h2 className="section__title">{setNames[setId] || `Set ${setId}`}</h2>
                    <div className="section__content mb-4">
                        {groupBy === WantListGroupBy.VariantType ? (
                            <GroupedWantCards cards={cards.sort((a, b) => compareCardIds(a.id, b.id))} isOwnWantList={isOwnWantList} />
                        ) : (
                            <div className="list-grid">
                                {cards
                                    .sort((a, b) => compareCardIds(a.id, b.id))
                                    .map(card => (
                                        <WantCard key={card.id} wantedCard={card} isOwnWantList={isOwnWantList} />
                                    ))}
                            </div>
                        )}
                    </div>
                </section>
            ))}
        </div>
    );
};

export default WantListDisplay;
