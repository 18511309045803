import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ContactUs: React.FC = () => {
    const email = 'swudash.feedback@gmail.com';

    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };

    return (
        <Container className="mt-4">
            <Card className="banner">
                <Card.Title className="display-6 mb-4 text-center">
                    Contact Us
                </Card.Title>
                <Card.Subtitle className="text-center mb-4">
                    We&apos;d love to hear from you!
                </Card.Subtitle>
                <Card.Text className="text-center mb-4">
                    Whether you have suggestions, found a bug, or just want to say hello,
                    please don&apos;t hesitate to reach out.
                </Card.Text>
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <p className="mb-3">You can contact us at:</p>
                    <Button
                        onClick={handleEmailClick}
                        className="hover-transform btn-lg"
                    >
                        <FontAwesomeIcon icon={faEnvelope} className="text-glow--gold me-2" />
                        {email}
                    </Button>
                </div>
            </Card>
        </Container >
    );
};

export default ContactUs;
