import React from 'react';
import EmptyListMessage from '../../_shared/EmptyListMessage';

interface EmptySaleListProps {
    isOwnProfile: boolean;
    user: string | undefined;
    isAuthenticated: boolean;
}

const EmptySaleList: React.FC<EmptySaleListProps> = (props) => {
    const messages = {
        title: "sale list",
        description: "Start building your sale list by adding cards you're looking to sell. This helps others know what you're interested in trading or selling!"
    };

    return (
        <EmptyListMessage
            {...props}
            editPath="/collections/my/sell/edit"
            messages={messages}
            showCollectionButton={true}
        />
    );
};

export default EmptySaleList;
