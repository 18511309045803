import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

interface HandleRequiredBannerProps {
    listType: 'sale' | 'want';
}

const HandleRequiredBanner: React.FC<HandleRequiredBannerProps> = ({ listType }) => {
    const navigate = useNavigate();

    return (
        <div className="card banner p-4">
            <div className="d-flex align-items-center justify-content-center gap-3">
                <FontAwesomeIcon
                    icon={faCircleExclamation}
                    className="text-warning"
                    size="2x"
                />
                <div className="text-start">
                    <h2 className="mb-2">Handle Required</h2>
                    <p className="text-muted mb-3">
                        You need to create a handle to manage your {listType} list.
                    </p>
                    <Button
                        variant="outline-primary"
                        onClick={() => navigate('/profile')}
                    >
                        Create Handle
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default HandleRequiredBanner;
