import React, { useState, useRef } from 'react';

interface MissingFieldProps {
    initialValue: { count: number | null; isDefault: boolean };
    onUpdate: (newValue: number | null) => void;
}

const MissingField: React.FC<MissingFieldProps> = ({ initialValue, onUpdate }) => {
    const [inputValue, setInputValue] = useState(initialValue.isDefault ? '' : initialValue.count?.toString() ?? '');
    const [isDefault, setIsDefault] = useState(initialValue.isDefault);
    const originalStateRef = useRef({ value: '', isDefault: initialValue.isDefault });

    const handleFocus = () => {
        originalStateRef.current = { value: inputValue, isDefault };
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleBlur = () => {
        if (inputValue === '') {
            setIsDefault(true);
            onUpdate(-1);
        } else {
            const newValue = parseInt(inputValue, 10);
            if (!isNaN(newValue) && newValue >= 0) {
                setIsDefault(false);
                onUpdate(newValue);
            } else {
                // Invalid input, revert to original state
                setInputValue(originalStateRef.current.value);
                setIsDefault(originalStateRef.current.isDefault);
            }
        }
    };

    return (
        <input
            type="number"
            value={inputValue}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            min="0"
            className={`inventory-edit-input ${isDefault ? 'missing-default-value' : 'missing-custom-value'}`}
            placeholder={initialValue.count?.toString() ?? ''}
        />
    );
};

export default MissingField;
