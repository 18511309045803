import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { useUser } from '../../../hooks/useUser';

interface OfferBannerProps {
    onCreateOffer: () => void;
    listType: 'sale' | 'want';
}

const OfferBanner: React.FC<OfferBannerProps> = ({ onCreateOffer, listType }) => {
    const { isAuthenticated, hasHandle } = useUser();

    const messages = {
        sale: "Interested in these cards?",
        want: "Have these cards?",
    };

    return (
        <div className="border-bottom mb-4 pb-3">
            <div className="d-flex align-items-center gap-3">
                <div>
                    {isAuthenticated ? (
                        hasHandle ? (
                            <Button
                                variant="outline-primary"
                                onClick={onCreateOffer}
                                className="px-4"
                            >
                                <FontAwesomeIcon icon={faHandshake} className="me-2" />
                                Make an Offer
                            </Button>
                        ) : (
                            <>
                                <h3 className="mb-1 fs-5">
                                    {messages[listType]}
                                </h3>
                                <p className="mb-0">
                                    <Link to="/profile" className="text-primary text-decoration-none">
                                        Set up your handle
                                    </Link>
                                    {" to start trading"}
                                </p>
                            </>
                        )
                    ) : (
                        <>
                            <h3 className="mb-1 fs-5">
                                {messages[listType]}
                            </h3>
                            <p className="mb-0">
                                <Link to="/login" className="text-primary text-decoration-none">
                                    Sign in
                                </Link>
                                {" to start trading"}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OfferBanner;
