import React from 'react';
import { Form } from 'react-bootstrap';

interface TextFilterProps {
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
}

const TextFilter: React.FC<TextFilterProps> = ({ value, onChange, disabled }) => (
    <Form.Group className="mb-3">
        <Form.Label>Search</Form.Label>
        <Form.Control
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Search cards..."
            disabled={disabled}
        />
    </Form.Group>
);

export default TextFilter;
