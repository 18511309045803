import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getCardVariantTypeDisplay } from '../../../utils/cardUtils';
import { CardItem, VariantItem } from './OfferModal';

interface CardListProps {
    cards: Array<CardItem>;
    selectedVariantIds: string[];
    onAddCard: (card: CardItem, variant: VariantItem) => void;
}

export const CardList: React.FC<CardListProps> = ({
    cards,
    selectedVariantIds,
    onAddCard
}) => (
    <div className="offer-section">
        <h3 className="offer-section__title">Available Cards</h3>
        <div className="offer-section__content">
            {cards.map(card => (
                <div key={card.id} className="offer-card">
                    <div className="offer-card__header">
                        <span className="offer-card__number">
                            {card.standardFullCardNumber}
                        </span>
                        <h4 className="offer-card__name">{card.name}</h4>
                    </div>

                    <div className="offer-card__variants">
                        {card.variants.map(variant => (
                            <div
                                key={variant.id}
                                className={`variant-row ${selectedVariantIds.includes(variant.id) ? 'variant-row--selected' : ''
                                    }`}
                            >
                                <span className="variant-row__type">
                                    {getCardVariantTypeDisplay(variant.type)}
                                    {variant.priceInCents && ` - $${(variant.priceInCents / 100).toFixed(2)}`}
                                    {variant.quantity && ` (x${variant.quantity})`}
                                </span>

                                {!selectedVariantIds.includes(variant.id) && (
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        className="btn-icon"
                                        onClick={() => onAddCard(card, variant)}
                                    >
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    </div>
);
