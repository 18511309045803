import React, { useState } from 'react';
import { useCreateOfferMutation, OfferTypeEnum } from '../../graphql/generated/graphql';
import OfferModal, { VariantItem, CardItem, SelectedCard } from './_shared/OfferModal';

interface CreateSellOfferModalProps {
    show: boolean;
    onHide: () => void;
    recipientHandle: string;
    wantListItems: Array<{
        id: string;
        cardDetails: {
            name: string;
            standardFullCardNumber: string;
        };
        variantCounts: Array<{
            variantId: string;
            variantType: string;
            count: number;
        }>;
    }>;
}

const CreateSellOfferModal: React.FC<CreateSellOfferModalProps> = ({
    show,
    onHide,
    recipientHandle,
    wantListItems
}) => {
    const [createOffer] = useCreateOfferMutation();
    const [selectedCards, setSelectedCards] = useState<Array<SelectedCard>>([]);

    // Transform wantListItems into CardItems format
    const availableCards: Array<CardItem> = wantListItems.map(item => ({
        id: item.id,
        name: item.cardDetails.name,
        standardFullCardNumber: item.cardDetails.standardFullCardNumber,
        variants: item.variantCounts.map(v => ({
            id: v.variantId,
            type: v.variantType,
            quantity: v.count,
            priceInCents: 0
        }))
    }));

    const handleAddCard = (card: CardItem, variant: VariantItem) => {
        setSelectedCards(prev => [...prev, {
            cardId: card.id,
            cardName: card.name,
            cardNumber: card.standardFullCardNumber,
            variantId: variant.id,
            variantType: variant.type,
            quantity: 1,
            priceInCents: 0,
            maxQuantity: variant.quantity
        }]);
    };

    const handleSubmit = async () => {
        await createOffer({
            variables: {
                input: {
                    recipientHandle,
                    offerType: OfferTypeEnum.Sell,
                    offerItems: selectedCards.map(card => ({
                        variantId: card.variantId,
                        quantity: card.quantity,
                        priceInCents: card.priceInCents
                    }))
                }
            }
        });
        onHide();
    };

    return (
        <OfferModal
            show={show}
            onHide={onHide}
            title="Create Sell Offer"
            availableCards={availableCards}
            selectedCards={selectedCards}
            onAddCard={handleAddCard}
            onRemoveCard={(variantId) =>
                setSelectedCards(prev => prev.filter(c => c.variantId !== variantId))
            }
            onQuantityChange={(variantId, quantity) =>
                setSelectedCards(prev => prev.map(c =>
                    c.variantId === variantId ? { ...c, quantity } : c
                ))
            }
            onPriceChange={(variantId, price) =>
                setSelectedCards(prev => prev.map(c =>
                    c.variantId === variantId
                        ? { ...c, priceInCents: Math.round(parseFloat(price) * 100) }
                        : c
                ))
            }
            onSubmit={handleSubmit}
        />
    );
};

export default CreateSellOfferModal;
