import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from '../../../graphql/generated/graphql';
import LoadingSpinner from '../../layout/LoadingSpinner';
import ErrorDisplay from '../../layout/ErrorDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

const Users: React.FC = () => {
    const { data, loading, error } = useGetUsersQuery();
    const navigate = useNavigate();

    if (loading) return <LoadingSpinner />;
    if (error) return <ErrorDisplay message={error.message} />;

    const handleEditClick = (e: React.MouseEvent, userId: string) => {
        e.stopPropagation();
        navigate(`/admin/users/${userId}/edit`);
    };

    return (
        <div className="section">
            <h2 className="section__title">Users</h2>
            <div className="table-container">
                <table className="table">
                    <thead className="table-header">
                        <tr>
                            <th>Email</th>
                            <th>Handle</th>
                            <th>Roles</th>
                            <th style={{ width: '80px' }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.users?.nodes?.map(user => (
                            <tr key={user?.id}>
                                <td>{user?.email}</td>
                                <td>
                                    {user?.handle ? (
                                        <span>{user.handle}</span>
                                    ) : (
                                        <span className="text-muted">—</span>
                                    )}
                                </td>
                                <td>
                                    {user?.roles.map(role => (
                                        <span
                                            key={role}
                                            className="game-card-hover-badge"
                                        >
                                            {role}
                                        </span>
                                    ))}
                                </td>
                                <td>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={(e) => handleEditClick(e, user?.id)}
                                        className="btn-icon"
                                    >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Users;
