import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CardSetCodeEnum, useGetUserWantListsForEditQuery } from '../../../../graphql/generated/graphql';
import CardPicker from './card_picker/CardPicker';
import EditWantListItemsTable from './EditWantListItemsTable';

interface WantListEditorProps {
    effectiveHandle: string | undefined;
    onItemsUpdated?: () => void;
}

const WantListEditor: React.FC<WantListEditorProps> = ({
    effectiveHandle,
    onItemsUpdated
}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedSet, setSelectedSet] = useState<CardSetCodeEnum | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showMissingOnly, setShowMissingOnly] = useState(true);

    const { data, loading, refetch } = useGetUserWantListsForEditQuery({
        variables: { userHandle: effectiveHandle || '' },
        skip: !effectiveHandle
    });

    const handleItemsUpdated = () => {
        refetch();
        onItemsUpdated?.();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSet(null);
        setSearchTerm('');
        setShowMissingOnly(false);
    };

    if (loading || !data?.userWantLists?.[0]) return null;

    const wantList = data.userWantLists[0];

    return (
        <div>
            <div className="d-flex justify-content-end mb-3">
                <Button
                    onClick={() => setShowModal(true)}
                    variant="add"
                >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Cards
                </Button>
            </div>

            <EditWantListItemsTable
                items={(wantList.items?.nodes?.filter((item): item is NonNullable<typeof item> => item !== null) ?? []).map(item => ({
                    id: item.id,
                    wantListId: wantList.id,
                    quantity: item.quantity,
                    cardVariant: {
                        id: item.cardVariant.id,
                        cardNumber: item.cardVariant.cardNumber,
                        variantType: item.cardVariant.variantType,
                        card: {
                            id: item.cardVariant.card.id,
                            name: item.cardVariant.card.name,
                            standardFullCardNumber: item.cardVariant.card.standardFullCardNumber,
                            aspects: item.cardVariant.card.aspects || []
                        }
                    }
                }))}
                wantListId={wantList.id}
                onItemUpdated={handleItemsUpdated}
            />

            <CardPicker
                show={showModal}
                onHide={handleCloseModal}
                selectedSet={selectedSet}
                onSelectSet={setSelectedSet}
                searchTerm={searchTerm}
                onSearchChange={setSearchTerm}
                showMissingOnly={showMissingOnly}
                onShowMissingOnlyChange={setShowMissingOnly}
                effectiveHandle={effectiveHandle}
                onItemUpdated={handleItemsUpdated}
                existingWantList={wantList}
            />
        </div>
    );
};

export default WantListEditor;
