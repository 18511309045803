import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { CardTypeEnum } from '../../graphql/generated/graphql';

interface CardTypeFilterProps {
    cardTypes: CardTypeEnum[];
    selected: CardTypeEnum[];
    onChange: (selected: CardTypeEnum[]) => void;
    disabled?: boolean;
}

const CardTypeFilter: React.FC<CardTypeFilterProps> = ({ cardTypes, selected, onChange, disabled }) => {
    const options = cardTypes.map(type => ({ value: type, label: type }));
    const value = selected.map(type => ({ value: type, label: type }));

    return (
        <Form.Group className="mb-3">
            <Form.Label>Card Type</Form.Label>
            <Select
                isMulti
                options={options}
                value={value}
                onChange={(selected) => onChange(selected ? selected.map(option => option.value) : [])}
                placeholder="Filter by type..."
                className="form-control-select"
                classNamePrefix="select"
                isDisabled={disabled}
            />
        </Form.Group>
    );
};

export default CardTypeFilter;
