import { useMemo, useState, useCallback } from 'react';
import { ArenaTypeEnum, AspectTypeEnum, Card, CardTypeEnum, RarityTypeEnum } from '../graphql/generated/graphql';
import { FilterOptions, FilterValue } from '../types/cardFilters';

type FilterFunction = (card: Card, filters: FilterOptions) => boolean;

const defaultFilters: FilterOptions = {
    searchText: '',
    selectedRarities: [],
    selectedCardTypes: [],
    selectedArenas: [],
    selectedCosts: [],
    selectedAspects: [],
    requiredAspects: [],
    selectedTraits: [],
    selectedKeywords: []
};

export const useCardFilters = (cards: Card[], customFilters: FilterFunction[] = []) => {
    const [filters, setFilters] = useState<FilterOptions>(defaultFilters);

    const textFilter = useCallback((card: Card, searchText: string) => {
        const lowercaseText = searchText.toLowerCase();
        return !searchText ||
            card.name.toLowerCase().includes(lowercaseText) ||
            card.standardCardNumber?.toLowerCase().includes(lowercaseText) ||
            card.hyperspaceCardNumber?.toLowerCase().includes(lowercaseText) ||
            card.subtitle?.toLowerCase().includes(lowercaseText);
    }, []);

    const rarityFilter = useCallback((card: Card, selectedRarities: RarityTypeEnum[]) => {
        return selectedRarities.length === 0 || selectedRarities.includes(card.rarity);
    }, []);

    const cardTypeFilter = useCallback((card: Card, selectedCardTypes: CardTypeEnum[]) => {
        return selectedCardTypes.length === 0 || selectedCardTypes.includes(card.cardType);
    }, []);

    const arenaFilter = useCallback((card: Card, selectedArenas: ArenaTypeEnum[]) => {
        return selectedArenas.length === 0 || (card.arena && selectedArenas.includes(card.arena));
    }, []);

    const costFilter = useCallback((card: Card, selectedCosts: number[]) => {
        return selectedCosts.length === 0 || selectedCosts.includes(card.cost);
    }, []);

    const aspectFilter = useCallback((card: Card, selectedAspects: AspectTypeEnum[], requiredAspects: AspectTypeEnum[]) => {
        if (!card.aspects) return false;

        // Required aspects must ALL be present
        if (!requiredAspects.every(aspect => card.aspects?.includes(aspect))) {
            return false;
        }

        // Normal aspect filtering (ANY of selected aspects)
        return selectedAspects.length === 0 || selectedAspects.some(aspect => card.aspects?.includes(aspect));
    }, []);

    const traitFilter = useCallback((card: Card, selectedTraits: string[]) => {
        return selectedTraits.length === 0 || selectedTraits.every(trait => card.traits?.includes(trait));
    }, []);

    const keywordFilter = useCallback((card: Card, selectedKeywords: string[]) => {
        return selectedKeywords.length === 0 || selectedKeywords.every(keyword => card.keywords?.includes(keyword));
    }, []);

    const filteredCards = useMemo(() => {
        return cards.filter(card => {
            const baseFiltersPass =
                textFilter(card, filters.searchText) &&
                rarityFilter(card, filters.selectedRarities) &&
                cardTypeFilter(card, filters.selectedCardTypes) &&
                arenaFilter(card, filters.selectedArenas) &&
                costFilter(card, filters.selectedCosts) &&
                aspectFilter(card, filters.selectedAspects, filters.requiredAspects) &&
                traitFilter(card, filters.selectedTraits) &&
                keywordFilter(card, filters.selectedKeywords);

            return baseFiltersPass && customFilters.every(filter => filter(card, filters));
        });
    }, [cards, filters, textFilter, rarityFilter, cardTypeFilter, arenaFilter, costFilter, aspectFilter, traitFilter, keywordFilter, customFilters]);

    const updateFilter = useCallback((key: keyof FilterOptions, value: FilterValue): void => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
    }, []);

    const calculateRarities = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.rarity))).filter(Boolean) as RarityTypeEnum[];
    }, [cards]);

    const calculateCardTypes = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.cardType))).filter(Boolean) as CardTypeEnum[];
    }, [cards]);

    const calculateArenas = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.arena).filter(Boolean))).sort();
    }, [cards]);

    const calculateCosts = useMemo(() => {
        return Array.from(new Set(cards.map(card => card.cost))).sort((a, b) => a - b);
    }, [cards]);

    const calculateTraits = useMemo(() => {
        return Array.from(new Set(cards.flatMap(card => card.traits || []))).sort();
    }, [cards]);

    const calculateKeywords = useMemo(() => {
        return Array.from(new Set(cards.flatMap(card => card.keywords || []))).sort();
    }, [cards]);

    return {
        filteredCards,
        filters,
        updateFilter,
        rarities: calculateRarities,
        cardTypes: calculateCardTypes,
        arenas: calculateArenas,
        costs: calculateCosts,
        traits: calculateTraits,
        keywords: calculateKeywords
    };
};
