import React, { useState } from 'react';
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { CardVariantTypeEnum, SaleListItemConditionEnum, useUpsertSaleListVariantMutation } from '../../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import { CardData } from '../../../../types/editSaleListTypes';
import { useAspects } from '../../../../hooks/useAspects';
import { getFormattedImageUrl } from '../../../../utils/imageUtils';
import { useSaleList } from './SaleListContext';

interface EditableSaleCardProps {
    saleCard: CardData;
    saleListId: string;
    onItemUpdated: () => void;
}

const EditableSaleCard: React.FC<EditableSaleCardProps> = ({ saleCard, saleListId, onItemUpdated }) => {
    const [updateSaleListVariantCount] = useUpsertSaleListVariantMutation();
    const { aspects } = useAspects();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [variantToRemove, setVariantToRemove] = useState<{ id: string; type: string } | null>(null);
    const { variants, updateVariant, removeVariant } = useSaleList();


    const getAspectImageUrl = (aspectType: string) => {
        const aspect = aspects.find(a => a.value === aspectType);
        return aspect ? getFormattedImageUrl(aspect.image, 20) : '';
    };

    const handleUpdate = async (variantId: string, newQuantity: number, priceInCents: number, condition: SaleListItemConditionEnum) => {
        if (newQuantity < 1) return;

        const updatedVariant = {
            saleCount: newQuantity,
            priceInCents,
            condition
        };

        // Update context immediately
        updateVariant(variantId, updatedVariant);

        try {
            await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: variantId,
                    quantity: newQuantity,
                    priceInCents,
                    condition
                }
            });
            onItemUpdated();
        } catch (error) {
            // Revert on error
            updateVariant(variantId, saleCard.variants[variantId]);
            console.error('Failed to update sale list item:', error);
        }
    };

    const handleRemoveClick = (variantId: string, variantType: string) => {
        setVariantToRemove({ id: variantId, type: variantType });
        setShowConfirmModal(true);
    };

    const handleConfirmRemove = async () => {
        if (!variantToRemove) return;

        // Get variant data with proper fallback chain
        const variantData = variants[variantToRemove.id] ||
            saleCard.variants[variantToRemove.type] ||
            Object.values(saleCard.variants).find(v => v.id === variantToRemove.id);

        if (!variantData) {
            console.error('Variant data not found', { variantToRemove, variants, saleCard });
            setShowConfirmModal(false);
            return;
        }

        try {
            // Remove from context first
            removeVariant(variantToRemove.id);

            await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: variantToRemove.id,
                    quantity: 0,
                    priceInCents: variantData.priceInCents,
                    condition: variantData.condition
                }
            });
            onItemUpdated();
        } catch (error) {
            // Restore variant on error
            updateVariant(variantToRemove.id, variantData);
            console.error('Failed to remove variant:', error);
        } finally {
            setShowConfirmModal(false);
        }
    };

    return (
        <>
            <article className="editable-sale-card">
                <header className="sale-card__header">
                    <span className="sale-card__number" aria-label="Card Number">
                        {saleCard.standardFullCardNumber}
                    </span>
                    <h3 className="sale-card__name">{saleCard.name}</h3>
                    <div className="sale-card__aspects">
                        {saleCard.aspects?.map((aspect, index) => (
                            <img
                                key={index}
                                src={getAspectImageUrl(aspect)}
                                alt={aspect}
                                title={aspect}
                                className="aspect-icon"
                            />
                        ))}
                    </div>
                </header>

                <div className="sale-card__variants">
                    {Object.entries(saleCard.variants).map(([variantType, variantData]) => {
                        const variantState = variants[variantData.id] || variantData;
                        return (
                            <div key={variantData.id} className="editable-sale-card__variant">
                                <div className="variant-row">
                                    <span className="variant-type">
                                        {getCardVariantTypeDisplay(variantType as CardVariantTypeEnum)}
                                    </span>
                                    <Button
                                        variant="outline-danger"
                                        className="btn-icon"
                                        onClick={() => handleRemoveClick(variantData.id, variantType)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </div>

                                <div className="variant-controls-row">
                                    <div className="editable-sale-card__controls">
                                        <Form.Select
                                            size="sm"
                                            value={variantState.saleCount}
                                            onChange={(e) => handleUpdate(
                                                variantData.id,
                                                parseInt(e.target.value),
                                                variantState.priceInCents,
                                                variantState.condition
                                            )}
                                        >
                                            {[1, 2, 3, 4, 5].map(num => (
                                                <option key={num} value={num}>{num}</option>
                                            ))}
                                        </Form.Select>
                                        <InputGroup size="sm" className="variant-price">
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faDollarSign} />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                value={(variantState.priceInCents / 100).toFixed(2)}
                                                onChange={(e) => handleUpdate(
                                                    variantData.id,
                                                    variantState.saleCount,
                                                    Math.round(parseFloat(e.target.value) * 100) || 0,
                                                    variantState.condition
                                                )}
                                            />
                                        </InputGroup>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </article>

            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove the {variantToRemove && getCardVariantTypeDisplay(variantToRemove.type as CardVariantTypeEnum)} variant of {saleCard.name} from your sale list?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirmRemove}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditableSaleCard;
