import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas, faUserFriends } from '@fortawesome/free-solid-svg-icons';

interface SharedListCardProps {
    handle: string;
    updatedAt: string;
    publiclyShared: boolean;
    onClick: () => void;
}

const SharedListCard: React.FC<SharedListCardProps> = ({
    handle,
    updatedAt,
    publiclyShared,
    onClick,
}) => (
    <Card
        className="shared-list__item hover-card clickable"
        onClick={onClick}
    >
        <Card.Body>
            <div className="d-flex align-items-center mb-2">
                <FontAwesomeIcon
                    icon={publiclyShared ? faGlobeAmericas : faUserFriends}
                    title={publiclyShared ? "Shared with everyone" : "Shared privately"}
                    className="me-2"
                />
                <Card.Title className="shared-list__name mb-0">
                    {handle}
                </Card.Title>
            </div>
            <Card.Text className="shared-list__info mb-0">
                Last updated: {new Date(updatedAt).toLocaleDateString()}
            </Card.Text>
        </Card.Body>
    </Card>
);

export default SharedListCard;
