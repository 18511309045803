import React from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getCardVariantTypeDisplay } from '../../../utils/cardUtils';
import { SelectedCard } from './OfferModal';

interface SelectedCardsProps {
    cards: Array<SelectedCard>;
    onRemove: (variantId: string) => void;
    onQuantityChange?: (variantId: string, quantity: number) => void;
    onPriceChange?: (variantId: string, price: string) => void;
    readOnlyPrices?: boolean;
}

export const SelectedCards: React.FC<SelectedCardsProps> = ({
    cards,
    onRemove,
    onQuantityChange,
    onPriceChange,
    readOnlyPrices
}) => (
    <div className="offer-section">
        <h3 className="offer-section__title">Selected Cards</h3>
        <div className="offer-section__content">
            {cards.map(card => (
                <div key={card.variantId} className="offer-card">
                    <div className="offer-card__header">
                        <span className="offer-card__number">
                            {card.cardNumber}
                        </span>
                        <h4 className="offer-card__name">{card.cardName}</h4>
                    </div>

                    <div className="variant-row">
                        <div className="variant-row__controls">
                            <Form.Control
                                type="text"
                                value={getCardVariantTypeDisplay(card.variantType)}
                                readOnly
                                className="variant-row__type"
                            />

                            <Form.Select
                                value={card.quantity}
                                onChange={(e) => onQuantityChange?.(card.variantId, parseInt(e.target.value))}
                                className="variant-row__quantity"
                                disabled={card.maxQuantity === 1}
                            >
                                {[...Array(card.maxQuantity)].map((_, i) => (
                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                ))}
                            </Form.Select>

                            <InputGroup className="variant-row__price">
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faDollarSign} />
                                </InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    value={(card.priceInCents / 100).toFixed(2)}
                                    onChange={e => onPriceChange?.(card.variantId, e.target.value)}
                                    readOnly={readOnlyPrices}
                                    min={0.01}
                                    step={0.01}
                                />
                            </InputGroup>

                            <Button
                                variant="outline-danger"
                                size="sm"
                                className="btn-icon"
                                onClick={() => onRemove(card.variantId)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);
