import React from 'react';
import { CardVariantTypeEnum } from '../../../../../graphql/generated/graphql';
import CardHover from '../../../../card/CardHover';
import CardVariantCell from './CardVariantCell';
import { CardData } from '../../../../../types/editSaleListTypes';

interface CardTableRowProps {
    card: CardData;
    variantColumns: CardVariantTypeEnum[];
    saleListId: string;
    showExtrasOnly: boolean;
    onItemUpdated: () => void;
}

const CardTableRow: React.FC<CardTableRowProps> = ({
    card,
    variantColumns,
    saleListId,
    showExtrasOnly,
    onItemUpdated
}) => {
    return (
        <tr>
            <td>
                {card.standardCardNumber}
            </td>
            <td>
                <CardHover cardId={card.id}>
                    <span>{card.name}</span>
                </CardHover>
            </td>
            {variantColumns.map((variantType) => (
                <CardVariantCell
                    key={variantType}
                    card={card}
                    variantType={variantType}
                    saleListId={saleListId}
                    showExtrasOnly={showExtrasOnly}
                    onItemUpdated={onItemUpdated}
                />
            ))}
        </tr>
    );
};

export default CardTableRow;
