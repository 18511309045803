import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

interface CardSearchProps {
    searchTerm: string;
    onSearchChange: (term: string) => void;
    showMissingOnly: boolean;
    onShowMissingOnlyChange: (show: boolean) => void;
    selectedSet: string;
}

const CardSearch: React.FC<CardSearchProps> = ({
    searchTerm,
    onSearchChange,
    showMissingOnly,
    onShowMissingOnlyChange,
    selectedSet
}) => {
    const filtersDisabled = !selectedSet;

    return (
        <Row className="align-items-center">
            <Col>
                <Form.Control
                    type="text"
                    placeholder="Search for cards..."
                    value={searchTerm}
                    onChange={(e) => onSearchChange(e.target.value)}
                    className={filtersDisabled ? 'disabled' : ''}
                    disabled={filtersDisabled}
                />
            </Col>
            <Col xs="auto">
                <Form.Check
                    type="switch"
                    id="missing-only-switch"
                    label="Only Show Missing Cards  "
                    checked={showMissingOnly}
                    onChange={(e) => onShowMissingOnlyChange(e.target.checked)}
                    disabled={filtersDisabled}
                />
            </Col>
        </Row>
    );
};

export default CardSearch;
