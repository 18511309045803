import React from 'react';
import { Form } from 'react-bootstrap';
import { AspectTypeEnum } from '../../graphql/generated/graphql';
import { useAspects } from "../../hooks/useAspects";
import { getFormattedImageUrl } from '../../utils/imageUtils';

interface AspectFilterProps {
    selected: AspectTypeEnum[];
    required: AspectTypeEnum[];
    onChange: (selected: AspectTypeEnum[], required: AspectTypeEnum[]) => void;
    disabled?: boolean;
}

const AspectFilter: React.FC<AspectFilterProps> = ({ selected, required, onChange, disabled }) => {
    const { aspects, loading: aspectsLoading } = useAspects();

    const handleAspectClick = (aspectType: AspectTypeEnum) => {
        if (required.includes(aspectType)) {
            // If it's required, remove it from required
            onChange(selected, required.filter(a => a !== aspectType));
        } else if (selected.includes(aspectType)) {
            // If it's selected, move it to required
            onChange(
                selected.filter(a => a !== aspectType),
                [...required, aspectType]
            );
        } else {
            // If it's neither, add it to selected
            onChange([...selected, aspectType], required);
        }
    };

    return (
        <Form.Group className="mb-3">
            <Form.Label>Aspects</Form.Label>
            <div className="form-aspect-grid">
                {aspects.map(aspect => (
                    <button
                        key={aspect.value}
                        onClick={() => handleAspectClick(aspect.value as AspectTypeEnum)}
                        disabled={disabled || aspectsLoading}
                        className={`form-aspect-button ${required.includes(aspect.value as AspectTypeEnum)
                                ? 'is-required'
                                : selected.includes(aspect.value as AspectTypeEnum)
                                    ? 'is-selected'
                                    : ''
                            }`}
                        title={`${aspect.label}${required.includes(aspect.value as AspectTypeEnum)
                                ? ' (Required)'
                                : selected.includes(aspect.value as AspectTypeEnum)
                                    ? ' (Any)'
                                    : ''
                            }`}
                    >
                        <img
                            src={getFormattedImageUrl(aspect.image, 40)}
                            alt={aspect.label}
                            className="form-aspect-image"
                        />
                    </button>
                ))}
            </div>
            <div className="text-muted small mt-2">
                Tap to cycle: off → any → required
            </div>
        </Form.Group>
    );
};

export default AspectFilter;
