import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Alert, InputGroup } from 'react-bootstrap';
import { SharedUser } from '../../../types/sharingTypes';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

interface ShareListModalProps {
    show: boolean;
    onHide: () => void;
    initialSharedWithAll: boolean;
    onSave: (shareWithAll: boolean, emails: string[]) => Promise<void>;
    isSaving: boolean;
    error?: string | null;
    sharedWithUsers: SharedUser[];
    shareableLink: string;
}

const ShareListModal: React.FC<ShareListModalProps> = ({
    show,
    onHide,
    initialSharedWithAll,
    onSave,
    isSaving,
    error,
    sharedWithUsers,
    shareableLink
}) => {
    const [shareWithEveryone, setShareWithEveryone] = useState(initialSharedWithAll);
    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState<string[]>([]);
    const [validationError, setValidationError] = useState<string | null>(null);
    const [copied, setCopied] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout>();


    useEffect(() => {
        setShareWithEveryone(initialSharedWithAll);
    }, [initialSharedWithAll]);

    useEffect(() => {
        if (show && sharedWithUsers) {
            setEmails(sharedWithUsers.map(user => user.email));
        }
    }, [show, sharedWithUsers]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleAddEmail = () => {
        if (!emailInput) return;

        if (!validateEmail(emailInput)) {
            setValidationError('Please enter a valid email address');
            return;
        }

        if (emails.includes(emailInput)) {
            setValidationError('This email has already been added');
            return;
        }

        setEmails([...emails, emailInput]);
        setEmailInput('');
        setValidationError(null);
    };

    const handleRemoveEmail = (emailToRemove: string) => {
        setEmails(emails.filter(email => email !== emailToRemove));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await onSave(shareWithEveryone, emails);
    };

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(shareableLink);
            setCopied(true);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const shareLinkSection = (
        <div className="mb-4">
            <Form.Label>Shareable Link</Form.Label>
            <InputGroup>
                <div className="form-control overflow-hidden text-truncate">
                    {shareableLink}
                </div>
                <Button
                    variant={copied ? "outline-success" : "outline-primary"}
                    onClick={handleCopyLink}
                >
                    <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
                </Button>
            </InputGroup>
        </div>
    );

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Share List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {error && (
                        <Alert variant="danger" className="mb-3">
                            {error}
                        </Alert>
                    )}

                    {shareLinkSection}

                    {/* Sharing Options Section */}
                    <div className="sharing-options mb-4">
                        <Form.Group className="mb-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <Form.Label>Share with everyone</Form.Label>
                                    <div className="small">Make this list visible to all users</div>
                                </div>
                                <div className="form-switch">
                                    <Form.Check
                                        type="switch"
                                        id="share-toggle"
                                        checked={shareWithEveryone}
                                        onChange={(e) => setShareWithEveryone(e.target.checked)}
                                        className="ms-2"
                                    />
                                </div>
                            </div>
                        </Form.Group>
                    </div>

                    {/* Specific People Section */}
                    <div className={shareWithEveryone ? 'opacity-50' : ''}>
                        <Form.Group className="mb-3">
                            <div>
                                <Form.Label>Share with specific people</Form.Label>
                                <div className="small mb-3">Enter email addresses to share with individual users</div>
                            </div>
                            <div className="d-flex gap-2">
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email address"
                                    value={emailInput}
                                    onChange={(e) => setEmailInput(e.target.value)}
                                    isInvalid={!!validationError}
                                    disabled={shareWithEveryone}
                                />
                                <Button
                                    variant="outline-primary"
                                    onClick={handleAddEmail}
                                    type="button"
                                    disabled={shareWithEveryone}
                                >
                                    Add
                                </Button>
                            </div>
                            {validationError && (
                                <Form.Text className="text-danger">
                                    {validationError}
                                </Form.Text>
                            )}
                        </Form.Group>

                        {/* Email Pills Section */}
                        {emails.length > 0 && (
                            <div className="mb-3">
                                <div className="d-flex flex-wrap gap-2">
                                    {emails.map((email) => {
                                        const sharedUser = sharedWithUsers.find(user => user.email === email);
                                        return (
                                            <div
                                                key={email}
                                                className="px-3 py-1 rounded-pill d-flex align-items-center border"
                                            >
                                                <span className="small">
                                                    {sharedUser
                                                        ? `${email}${sharedUser.handle ? ` (${sharedUser.handle})` : ''}`
                                                        : email
                                                    }
                                                </span>
                                                <Button
                                                    variant="link"
                                                    className="text-danger p-0 ps-2 text-decoration-underline"
                                                    onClick={() => handleRemoveEmail(email)}
                                                    aria-label={`Remove ${email}`}
                                                >
                                                    x
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Action Buttons */}
                    <div className="d-flex justify-content-end gap-2">
                        <Button
                            variant="outline-cancel"
                            onClick={onHide}
                            type="button"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outline-primary"
                            type="submit"
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal >
    );
};

export default ShareListModal;
