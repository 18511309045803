import React from 'react';
import { useGetCardSetsQuery } from '../../graphql/generated/graphql';
import CardSetCard, { CardSetSkeleton } from '../shared/CardSetCard';
import { Container } from 'react-bootstrap';

const CardSets: React.FC = () => {
    const { loading, error, data } = useGetCardSetsQuery();

    const renderCardSets = () => {
        if (loading) {
            <div className="grid grid--cards">
                {[...Array(3)].map((_, index) => (
                    <CardSetSkeleton key={index} />
                ))}
            </div>
        }

        if (error) {
            return (
                <div className="banner banner--error">
                    Error: {error.message}
                </div>
            );
        }

        return (
            <div className="grid grid--cards">
                {data?.cardSets?.nodes?.slice(0, 3).map((set) => (
                    <CardSetCard
                        key={set?.id}
                        id={set?.id || ''}
                        code={set?.code || ''}
                        name={set?.name || ''}
                        description={set?.description || ''}
                        imageUrl={set?.imageUrl || ''}
                    />
                ))}
            </div>
        );
    };

    return (
        <Container>
            <section className="section mt-5 mb-4">
                <h2 className="section__title">Card Sets</h2>
                {renderCardSets()}
            </section>
        </Container>
    );
};

export default CardSets;
