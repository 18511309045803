import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface RarityFilterProps {
    rarities: string[];
    selected: string[];
    onChange: (selected: string[]) => void;
    disabled?: boolean;
}

const RarityFilter: React.FC<RarityFilterProps> = ({ rarities, selected, onChange, disabled }) => {
    const options = rarities.map(rarity => ({ value: rarity, label: rarity }));
    const value = selected.map(rarity => ({ value: rarity, label: rarity }));

    return (
        <Form.Group className="mb-3">
            <Form.Label>Rarity</Form.Label>
            <Select
                isMulti
                options={options}
                value={value}
                onChange={(selected) => onChange(selected ? selected.map(option => option.value) : [])}
                placeholder="Filter by rarity..."
                className="form-control-select"
                classNamePrefix="select"
                isDisabled={disabled}
            />
        </Form.Group>
    );
};

export default RarityFilter;
