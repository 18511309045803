import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetCardSetsQuery } from '../graphql/generated/graphql';
import { useAuth } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import HomeBannerCard from './HomeBannerCard';
import { CardSetSkeleton } from './shared/CardSetCard';
import CardSetCard from './shared/CardSetCard';

const Home: React.FC = () => {
    const { loading, error, data } = useGetCardSetsQuery();
    const { isAuthenticated } = useAuth();

    const renderBannerContent = () => (
        isAuthenticated ? (
            <>
                <Card.Title as="h3">
                    <FontAwesomeIcon icon={faBook} className="me-2" />
                    My Collection
                </Card.Title>
                <Card.Text className="card-component__subtitle">
                    View and manage your personal card collection.
                </Card.Text>
                <Link to="/collections/my" className="btn btn-primary">
                    Go to My Collection
                </Link>
            </>
        ) : (
            <>
                <Card.Title as="h3">
                    Start Your Collection Journey!
                </Card.Title>
                <Card.Text>
                    Want to track your card collection, share your want list, and trade with the community?{' '}
                    <Link to="/login" className="nav__link">Log in</Link> to get started!
                </Card.Text>
            </>
        )
    );

    const renderCardSets = () => {
        if (loading) {
            <div className="grid grid--cards">
                {[...Array(3)].map((_, index) => (
                    <CardSetSkeleton key={index} />
                ))}
            </div>
        }

        if (error) {
            return (
                <div className="banner banner--error">
                    Error: {error.message}
                </div>
            );
        }

        return (
            <div className="grid grid--cards">
                {data?.cardSets?.nodes?.slice(0, 3).map((set) => (
                    <CardSetCard
                        key={set?.id}
                        id={set?.id || ''}
                        code={set?.code || ''}
                        name={set?.name || ''}
                        description={set?.description || ''}
                        imageUrl={set?.imageUrl || ''}
                    />
                ))}
            </div>
        );
    };

    return (
        <Container>
            <HomeBannerCard>
                {renderBannerContent()}
            </HomeBannerCard>

            <section className="section">
                <h2 className="section__title">Explore Card Sets</h2>
                {renderCardSets()}
            </section>
        </Container>
    );
};

export default Home;
