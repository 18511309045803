import React from 'react';
import { Modal } from 'react-bootstrap';
import { CardSetCodeEnum, WantList } from '../../../../../graphql/generated/graphql';
import CardSetSelector from '../../../_shared/card_picker/CardSetSelector';
import CardSearch from './CardSearch';
import CardTable from './CardTable';

interface CardPickerProps {
    show: boolean;
    onHide: () => void;
    selectedSet: CardSetCodeEnum | null;
    onSelectSet: (set: CardSetCodeEnum) => void;
    searchTerm: string;
    onSearchChange: (term: string) => void;
    showMissingOnly: boolean;
    onShowMissingOnlyChange: (show: boolean) => void;
    effectiveHandle: string | undefined;
    onItemUpdated: () => void;
    existingWantList: WantList;
}

const CardPicker: React.FC<CardPickerProps> = ({
    show,
    onHide,
    selectedSet,
    onSelectSet,
    searchTerm,
    onSearchChange,
    showMissingOnly,
    onShowMissingOnlyChange,
    effectiveHandle,
    onItemUpdated,
    existingWantList
}) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Cards to Want List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <CardSetSelector
                        selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                        onSelectSet={onSelectSet}
                    />
                </div>
                <div className="mb-4">
                    <CardSearch
                        selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                        searchTerm={searchTerm}
                        onSearchChange={onSearchChange}
                        showMissingOnly={showMissingOnly}
                        onShowMissingOnlyChange={onShowMissingOnlyChange}
                    />
                </div>

                <CardTable
                    selectedSet={selectedSet ?? CardSetCodeEnum.Sor}
                    searchTerm={searchTerm}
                    showMissingOnly={showMissingOnly}
                    effectiveHandle={effectiveHandle}
                    onItemUpdated={onItemUpdated}
                    existingWantList={existingWantList}
                />

            </Modal.Body>
        </Modal>
    );
};

export default CardPicker;
