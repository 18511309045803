import React, { useState, useEffect } from 'react';

interface InventoryFieldProps {
    initialValue: number;
    onUpdate: (newValue: number | null) => void;
}

const InventoryField: React.FC<InventoryFieldProps> = ({ initialValue, onUpdate }) => {
    const [value, setValue] = useState(initialValue.toString());
    const [originalValue, setOriginalValue] = useState(initialValue.toString());

    useEffect(() => {
        setOriginalValue(initialValue.toString());
    }, [initialValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleBlur = () => {
        if (value === '') {
            // If the field is blank, revert to the original value
            setValue(originalValue);
        } else {
            const newValue = parseInt(value, 10);
            if (!isNaN(newValue) && newValue >= 0) {
                onUpdate(newValue);
            } else {
                // If the value is invalid, revert to the original value
                setValue(originalValue);
            }
        }
    };

    return (
        <input
            type="number"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            min="0"
            className="inventory-edit-input"
            title="Inventory"
        />
    );
};

export default InventoryField;
