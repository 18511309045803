import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

interface HandleSectionProps {
    handle: string
    savedHandle: string
    onHandleChange: (newHandle: string) => void
    onHandleSave: () => Promise<void>
    error: string
    isLoading: boolean
}

const HandleSection: React.FC<HandleSectionProps> = ({
    handle,
    savedHandle,
    onHandleChange,
    onHandleSave,
    error,
    isLoading
}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [validationError, setValidationError] = useState<string>('')

    const handleRegex = /^[a-z0-9_-]+$/
    const isHandleSet = Boolean(savedHandle)

    const validateHandle = (): boolean => {
        setValidationError('')

        if (!handle) {
            setValidationError('Handle cannot be empty.')
            return false
        }

        if (!handleRegex.test(handle)) {
            setValidationError('Handle must contain only lowercase letters, numbers, dash, or underscore.')
            return false
        }

        return true
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (validateHandle()) {
            setShowConfirmModal(true)
        }
    }

    const handleConfirmSave = async () => {
        setShowConfirmModal(false)
        await onHandleSave()
        if (!error) {
            setIsEditing(false)
        }
    }

    const handleCancel = () => {
        setIsEditing(false)
        onHandleChange('')
        setValidationError('')
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <div className="d-flex align-items-center mb-2">
                        <label className="fw-bold mb-0">Handle</label>
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="ms-2"
                            title="Your handle is used to identify you across the platform"
                        />
                    </div>

                    {isHandleSet ? (
                        <div className="d-flex align-items-center mt-2">
                            <p className="fs-5 text-primary mb-0">{savedHandle}</p>
                        </div>
                    ) : (
                        <div className="mt-2">

                            {!isEditing ? (
                                <div className="d-flex flex-column gap-2">
                                    <Button
                                        variant="outline-primary"
                                        className="px-3 align-self-start"
                                        onClick={() => setIsEditing(true)}
                                    >
                                        Set Handle
                                    </Button>
                                    <div className="banner banner--warning">
                                        <FontAwesomeIcon icon={faCircleExclamation} className="me-2" />
                                        <span>You need to set a handle to share your collections. Choose carefully as it can only be set once!</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="mt-2">
                                    <Form.Control
                                        type="text"
                                        value={handle}
                                        onChange={(e) => onHandleChange(e.target.value.toLowerCase())}
                                        placeholder="Enter handle"
                                    />
                                    {(validationError || error) && (
                                        <Form.Text className="text-danger">
                                            {validationError || error}
                                        </Form.Text>
                                    )}
                                    <div className="mt-3">
                                        <Button
                                            variant="outline-primary"
                                            type="submit"
                                            className="me-2"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? 'Saving...' : 'Save Handle'}
                                        </Button>
                                        <Button
                                            variant="outline-cancel"
                                            onClick={handleCancel}
                                            disabled={isLoading}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Form.Group>
            </Form>

            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Handle Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>You are about to set your handle to: <strong>{handle}</strong></p>
                    <div className="d-flex align-items-start">
                        <FontAwesomeIcon icon={faCircleExclamation} className="me-2 mt-1 text-warning" />
                        <p className="mb-0">Please note that your handle can only be set once and cannot be changed later. Are you sure you want to proceed?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-cancel" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="outline-primary" onClick={handleConfirmSave}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default HandleSection
