import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CardVariantTypeEnum, useUpdateWantListVariantCountMutation } from '../../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import { CardData } from '../../../../types/wantListTypes';
import { getFormattedImageUrl } from '../../../../utils/imageUtils';
import { useAspects } from '../../../../hooks/useAspects';

interface EditableWantCardProps {
    wantedCard: CardData;
    wantListId: string;
    onItemUpdated: () => void;
}

const EditableWantCard: React.FC<EditableWantCardProps> = ({ wantedCard, wantListId, onItemUpdated }) => {
    const [updateWantListVariantCount] = useUpdateWantListVariantCountMutation();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [variantToRemove, setVariantToRemove] = useState<{ id: string; type: string } | null>(null);

    const handleQuantityChange = async (variantId: string, newQuantity: number) => {
        if (newQuantity < 1) return;
        try {
            await updateWantListVariantCount({
                variables: { wantListId, cardVariantId: variantId, quantity: newQuantity }
            });
            onItemUpdated();
        } catch (error) {
            console.error('Failed to update quantity:', error);
        }
    };

    const handleRemoveClick = (variantId: string, variantType: string) => {
        setVariantToRemove({ id: variantId, type: variantType });
        setShowConfirmModal(true);
    };

    const handleConfirmRemove = async () => {
        if (!variantToRemove) return;
        try {
            await updateWantListVariantCount({
                variables: { wantListId, cardVariantId: variantToRemove.id, quantity: 0 }
            });
            onItemUpdated();
            setShowConfirmModal(false);
        } catch (error) {
            console.error('Failed to remove variant:', error);
        }
    };

    const { aspects } = useAspects();
    const getAspectImageUrl = (aspectType: string) => {
        const aspect = aspects.find(a => a.value === aspectType);
        return aspect ? getFormattedImageUrl(aspect.image, 20) : '';
    };

    return (
        <>
            <article className="editable-want-card">
                <header className="want-card__header">
                    <span className="want-card__number" aria-label="Card Number">
                        {wantedCard.standardFullCardNumber}
                    </span>
                    <h3 className="want-card__name">{wantedCard.name}</h3>
                    <div className="want-card__aspects">
                        {wantedCard.aspects?.map((aspect, index) => (
                            <img
                                key={index}
                                src={getAspectImageUrl(aspect)}
                                alt={aspect}
                                title={aspect}
                                className="aspect-icon"
                            />
                        ))}
                    </div>
                </header>
                <div className="want-card__variants">
                    {Object.entries(wantedCard.variants).map(([variantType, variantData]) => (
                        <div key={variantData.id} className="editable-want-card__variant">
                            <span> {getCardVariantTypeDisplay(variantType as CardVariantTypeEnum)} </span>
                            <div className="editable-want-card__controls">
                                <Form.Select
                                    size="sm"
                                    value={variantData.wantCount}
                                    onChange={(e) => handleQuantityChange(variantData.id, parseInt(e.target.value))}
                                >
                                    {[1, 2, 3, 4, 5].map(num => (
                                        <option key={num} value={num}>{num}</option>
                                    ))}
                                </Form.Select>
                                <Button
                                    variant="outline-danger"
                                    onClick={() => handleRemoveClick(variantData.id, variantType)}
                                    className="btn-icon"
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </article>

            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} className="modal-dark">
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove the {variantToRemove && getCardVariantTypeDisplay(variantToRemove.type as CardVariantTypeEnum)} variant of {wantedCard.name} from your want list?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowConfirmModal(false)} variant="outline-cancel">
                        Cancel
                    </Button>
                    <Button variant="outline-danger" onClick={handleConfirmRemove}>
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditableWantCard;
