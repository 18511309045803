import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import {
    CardVariantTypeEnum,
    SaleListItemConditionEnum,
    useUpsertSaleListVariantMutation
} from '../../../../../graphql/generated/graphql';
import { CardData } from '../../../../../types/editSaleListTypes';
import { useSaleList } from '../SaleListContext';

interface CardVariantCellProps {
    card: CardData;
    variantType: CardVariantTypeEnum;
    saleListId: string;
    showExtrasOnly: boolean;
    onItemUpdated: () => void;
}

const MIN_PRICE_CENTS = 1; // 1 cent minimum

const CardVariantCell: React.FC<CardVariantCellProps> = ({
    card,
    variantType,
    saleListId,
    showExtrasOnly,
    onItemUpdated
}) => {
    const [updateSaleListVariantCount] = useUpsertSaleListVariantMutation();
    const [showModal, setShowModal] = useState(false);
    const [price, setPrice] = useState('');
    const [priceError, setPriceError] = useState('');
    const { variants, updateVariant } = useSaleList();

    const cardVariant = card.variants[variantType];
    const variantState = cardVariant ? variants[cardVariant.id] : null;


    if (!cardVariant || (showExtrasOnly && cardVariant.extraCount === 0)) {
        return <td className="sale-list-variant">—</td>;
    }

    if (variantState?.saleCount > 0) {
        return <td className="sale-list-variant added">Added</td>;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const priceFloat = parseFloat(price);
        const priceInCents = Math.round(priceFloat * 100);

        if (isNaN(priceFloat) || priceInCents < MIN_PRICE_CENTS) {
            setPriceError('Price must be at least $0.01');
            return;
        }

        const newVariant = {
            saleCount: 1,
            priceInCents,
            condition: SaleListItemConditionEnum.NearMint
        };

        // Update context first
        updateVariant(cardVariant.id, newVariant);

        try {
            const response = await updateSaleListVariantCount({
                variables: {
                    saleListId,
                    cardVariantId: cardVariant.id,
                    quantity: 1,
                    priceInCents,
                    condition: SaleListItemConditionEnum.NearMint
                }
            });

            if (response.data?.upsertSaleListVariant?.saleListItem) {
                onItemUpdated();
                setShowModal(false);
            }
        } catch (error) {
            // Revert on error
            updateVariant(cardVariant.id, null);
            console.error('Failed to update sale list:', error);
        }
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPriceError('');
        // Allow empty string, digits, and up to one decimal point with up to 2 decimal places
        if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
            setPrice(value);
        }
    };

    return (
        <td className="sale-list-variant">
            <Button
                variant="outline-add"
                size="sm"
                onClick={() => {
                    setShowModal(true);
                    setPrice('');
                    setPriceError('');
                }}
                title="Add to sale list"
            >
                Add
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Card to Sale List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Price ($)</Form.Label>
                            <Form.Control
                                type="text"
                                value={price}
                                onChange={handlePriceChange}
                                placeholder="0.00"
                                required
                                isInvalid={!!priceError}
                            />
                            <Form.Control.Feedback type="invalid">
                                {priceError}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <div className="d-flex justify-content-end gap-2">
                            <Button variant="outline-cancel" onClick={() => setShowModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="outline-primary" type="submit">
                                Add to Sale List
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </td>
    );
};

export default CardVariantCell;
