import React from 'react';
import { CardVariantTypeEnum } from '../../../../graphql/generated/graphql';
import { getCardVariantTypeDisplay } from '../../../../utils/cardUtils';
import { CardData } from '../../../../types/saleListTypes';
import { useAspects } from '../../../../hooks/useAspects';
import { getFormattedImageUrl } from '../../../../utils/imageUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

interface SaleCardProps {
    saleCard: CardData;
    isOwnSaleList: boolean;
}

const SaleCard: React.FC<SaleCardProps> = ({ saleCard }) => {
    const { aspects } = useAspects();
    const getAspectImageUrl = (aspectType: string) => {
        const aspect = aspects.find(a => a.value === aspectType);
        return aspect ? getFormattedImageUrl(aspect.image, 20) : '';
    };

    const formatPrice = (priceInCents: number): string => {
        return (priceInCents / 100).toFixed(2);
    };

    return (
        <article className="sale-card">
            <header className="sale-card__header">
                <span className="sale-card__number" aria-label="Card Number">
                    {saleCard.standardFullCardNumber}
                </span>
                <h3 className="sale-card__name">{saleCard.name}</h3>
                <div className="sale-card__aspects">
                    {saleCard.aspects?.map((aspect, index) => (
                        <img
                            key={index}
                            src={getAspectImageUrl(aspect)}
                            alt={aspect}
                            title={aspect}
                            className="aspect-icon"
                        />
                    ))}
                </div>
            </header>

            <div className="sale-card__variants">
                {Object.entries(saleCard.variants).map(([variantType, variantData]) => (
                    <div key={variantData.id} className="sale-card__variant">
                        <div className="variant-info">
                            <span className="variant-count">{variantData.saleCount}x</span>
                            <span className="variant-type">{getCardVariantTypeDisplay(variantType as CardVariantTypeEnum)}</span>
                        </div>
                        <span className="variant-price">
                            <FontAwesomeIcon icon={faDollarSign} />
                            {formatPrice(variantData.priceInCents)}
                        </span>
                    </div>
                ))}
            </div>
        </article>
    );
};

export default SaleCard;
