import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const AdminDashboard: React.FC = () => {
    return (
        <div className="admin-main">
            <h1 className="section__title">Admin Dashboard</h1>
            <Row className="g-4">
                <Col md={4}>
                    <Card className="hover-card">
                        <Card.Body>
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                    icon={faUsers}
                                    className="fa-2x me-3"
                                />
                                <div>
                                    <h3 className="card-title">Users</h3>
                                    <Link
                                        to="/admin/users"
                                        className="btn btn-primary btn-sm"
                                    >
                                        Manage Users →
                                    </Link>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AdminDashboard;
