import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface ArenaFilterProps {
    arenas: string[];
    selected: string[];
    onChange: (selected: string[]) => void;
    disabled?: boolean;
}

const ArenaFilter: React.FC<ArenaFilterProps> = ({ arenas, selected, onChange, disabled }) => {
    const options = arenas.map(arena => ({ value: arena, label: arena }));
    const value = selected.map(arena => ({ value: arena, label: arena }));

    return (
        <Form.Group className="mb-3">
            <Form.Label>Arena</Form.Label>
            <Select
                isMulti
                options={options}
                value={value}
                onChange={(selected) => onChange(selected ? selected.map(option => option.value) : [])}
                placeholder="Filter by arena..."
                className="form-control-select"
                classNamePrefix="select"
                isDisabled={disabled}
            />
        </Form.Group>
    );
};

export default ArenaFilter;
